<style scoped>
.navigation-footer {
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px;
    height: 50px;
}
.navigation-footer__sheet {
    overflow: hidden;
}
.navigation-center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
</style>

<template>
    <div
        class="navigation-footer d-flex justify-space-between"
        :style="`z-index: ${overlay ? 201 : 1}`"
    >
        <div>
            <v-sheet
                color="white"
                elevation="1"
                rounded="xl"
                class="navigation-footer__sheet"
            >
                <v-item-group>
                    <slot name="navigationLeft"></slot>
                </v-item-group>
            </v-sheet>
        </div>
        <div class="navigation-center">
            <v-sheet
                color="primary"
                elevation="1"
                rounded="xl"
                class="navigation-footer__sheet"
            >
                <v-item-group>
                    <slot></slot>
                </v-item-group>
            </v-sheet>
        </div>
        <div>
            <v-sheet
                color="primary"
                elevation="1"
                rounded="xl"
                class="navigation-footer__sheet"
            >
                <v-item-group>
                    <slot name="navigationRight"></slot>
                </v-item-group>
            </v-sheet>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NavigationFooter',
    components: {},
    props: {
        overlay: Boolean,
    },
    computed: {
        hasLeftSlot() {
            return !!this.$slots.navigationLeft;
        },
        hasRightSlot() {
            return !!this.$slots.navigationRight;
        },
    },

    mounted() {},
    data: () => ({}),
    methods: {},
    watch: {},
};
</script>
