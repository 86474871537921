import store from './index.js';

export function GetAuthorisationHeaderGroup() {
    var token = store.state.authentication.accessToken;
    return { headers: buildAuthorisationHeader(token) };
}

export function GetBearerToken() {
    return 'Bearer ' + store.state.authentication.accessToken;
}

export function GetBearerHeader() {
    var token = store.state.authentication.accessToken;
    return buildAuthorisationHeader(token);
}

function buildAuthorisationHeader(token) {
    return { Authorization: 'Bearer ' + token };
}
