<style scoped>
.root {
    max-width: 900px;
    margin-top: 100px;
}
</style>

<template>
    <v-main class="main">
        <navigation-header>
            <template v-slot:navigationLeft>
                <v-btn small text class="pa-6" to="/">
                    <v-icon left>
                        mdi-arrow-left
                    </v-icon>
                    Dashboard
                </v-btn>
            </template>
        </navigation-header>

        <v-container class="root" xs>
            <h1 class="title mb-5">Add/Edit a shopper</h1>
            <v-form>
                <v-card>
                    <v-row no-gutters>
                        <v-col cols="12" sm="4">
                            <v-layout fill-height justify-center align-center>
                                <v-img
                                    v-if="shopper.resources[0]"
                                    height="100%"
                                    width="100%"
                                    cover
                                    :src="shopper.resources[0].url"
                                    class="align-end"
                                >
                                    <template v-slot:placeholder>
                                        <v-row
                                            class="fill-height ma-0"
                                            align="center"
                                            justify="center"
                                        >
                                            <v-progress-circular
                                                indeterminate
                                                color="primary"
                                                :size="20"
                                                :width="3"
                                            ></v-progress-circular>
                                        </v-row>
                                    </template>
                                    <v-layout justify-center class="ma-3">
                                        <v-btn
                                            elevation="2"
                                            fab
                                            small
                                            dark
                                            color="error"
                                            @click="removeImage()"
                                        >
                                            <v-icon>mdi-delete-outline</v-icon>
                                        </v-btn>
                                    </v-layout>
                                </v-img>
                                <label
                                    v-else
                                    for="upload-file"
                                    class="v-btn v-btn--outlined v-btn--text theme--light v-size--default"
                                >
                                    <input
                                        id="upload-file"
                                        type="file"
                                        ref="file"
                                        accept=".jpg, .jpeg, .png"
                                        @change="
                                            addImage($event.target.files[0])
                                        "
                                        hidden
                                    />
                                    <span class="v-btn__content"
                                        >Add image</span
                                    >
                                    <v-icon
                                        aria-label="Add image"
                                        role="img"
                                        aria-hidden="false"
                                        medium
                                        class="ml-2"
                                    >
                                        mdi-account-box
                                    </v-icon>
                                </label>
                            </v-layout>
                        </v-col>
                        <v-col cols="12" sm="8">
                            <v-container class="px-5">
                                <v-text-field
                                    v-model="shopper.title"
                                    label="Title"
                                    required
                                    :error-messages="titleErrors"
                                    @input="$v.shopper.title.$touch()"
                                    @blur="$v.shopper.title.$touch()"
                                    class="mb-3"
                                    hide-details="auto"
                                ></v-text-field>

                                <!-- <v-select
                                    :items="environments"
                                    v-model="shopper.environmentid"
                                    label="Select an environment"
                                    item-text="id"
                                    clearable
                                    class="mb-5"
                                    hide-details="auto"
                                ></v-select> -->

                                <v-radio-group
                                    v-model="shopper.environmentid"
                                    row
                                >
                                    <template v-slot:label>
                                        <div>
                                            Select an environment:
                                        </div>
                                    </template>

                                    <v-radio
                                        v-for="item in environments"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.name"
                                    ></v-radio>
                                </v-radio-group>

                                <vue-editor
                                    id="editor"
                                    v-model="shopper.body"
                                    :editor-toolbar="customToolbar"
                                    class="mb-4"
                                ></vue-editor>

                                <v-combobox
                                    v-model="shopper.tags"
                                    :items="allShopperTags"
                                    chips
                                    clearable
                                    label="Select or enter a new tag"
                                    hide-details="auto"
                                    multiple
                                    class="mb-5"
                                >
                                    <template
                                        v-slot:selection="{
                                            attrs,
                                            item,
                                            select,
                                            selected,
                                        }"
                                    >
                                        <v-chip
                                            v-bind="attrs"
                                            :input-value="selected"
                                            close
                                            @click="select"
                                            @click:close="removeTags(item)"
                                            small
                                            color="primary"
                                        >
                                            <strong>{{ item }}</strong>
                                        </v-chip>
                                    </template>
                                </v-combobox>

                                <h3 class="caption text--secondary mb-2">
                                    Add useful links
                                </h3>

                                <v-card elevation="0" outlined>
                                    <v-container
                                        v-if="shopper.links.length > 0"
                                    >
                                        <v-row
                                            v-for="(item, i) in shopper.links"
                                            :key="i"
                                        >
                                            <v-col cols="12" sm="4">
                                                <v-text-field
                                                    v-model="item.title"
                                                    label="Link title"
                                                    hide-details="auto"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-text-field
                                                    v-model="item.url"
                                                    label="Link url"
                                                    hint="https://www.example.com/"
                                                    persistent-hint
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="2">
                                                <v-layout
                                                    fill-height
                                                    align-center
                                                >
                                                    <v-btn
                                                        icon
                                                        small
                                                        depressed
                                                        color="red"
                                                        class="mr-2"
                                                        @click="removeLink(i)"
                                                    >
                                                        <v-icon>
                                                            mdi-trash-can-outline
                                                        </v-icon>
                                                    </v-btn>

                                                    <v-btn
                                                        v-if="
                                                            i ===
                                                                shopper.links
                                                                    .length -
                                                                    1
                                                        "
                                                        icon
                                                        small
                                                        depressed
                                                        color="primary"
                                                        @click="addLink()"
                                                    >
                                                        <v-icon>
                                                            mdi-link-plus
                                                        </v-icon>
                                                    </v-btn>
                                                </v-layout>
                                            </v-col>
                                        </v-row>
                                    </v-container>

                                    <v-layout v-else justify-end class="pa-3">
                                        <v-btn
                                            text
                                            small
                                            depressed
                                            color="primary"
                                            @click="addLink()"
                                        >
                                            Add Link
                                            <v-icon right>
                                                mdi-link-plus
                                            </v-icon>
                                        </v-btn>
                                    </v-layout>
                                </v-card>
                            </v-container>
                        </v-col>
                    </v-row>

                    <v-divider></v-divider>
                    <v-card-actions class="justify-end pa-4">
                        <v-btn text @click="onDelete" outlined>
                            Delete
                        </v-btn>
                        <v-btn
                            color="primary"
                            :disabled="isLoading || saved"
                            @click="onSave"
                            class="ml-2"
                        >
                            <v-progress-circular
                                indeterminate
                                color="primary"
                                :size="20"
                                :width="3"
                                v-if="isLoading"
                            ></v-progress-circular>
                            <span v-else>
                                Save
                            </span>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-container>
        <v-snackbar
            :timeout="-1"
            :value="!!isLoadingMessage"
            color="primary"
            rounded="pill"
        >
            <p class="text-center mb-0">{{ isLoadingMessage }}</p>
        </v-snackbar>
    </v-main>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import shortid from 'shortid';
import axios from 'axios';
import NavigationHeader from '../components/Global/NavigationHeader';
import { required } from 'vuelidate/lib/validators';
import { VueEditor } from 'vue2-editor';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

import { v4 as uuidv4, validate as uuidValidateV4 } from 'uuid';

import { uploadResource } from '../actions/resourcesActions';

export default {
    name: 'AddShoper',
    components: { NavigationHeader, VueEditor },
    props: {},
    computed: {
        ...mapState('environments', ['environments']),
        ...mapGetters('shoppers', ['getShopper', 'allShopperTags']),

        storedShopper() {
            const { shopperId } = this.$router.history.current.params;
            return this.getShopper(shopperId);
        },

        titleErrors() {
            const errors = [];
            if (!this.$v.shopper.title.$dirty) return errors;
            !this.$v.shopper.title.required &&
                errors.push('Title is required.');
            return errors;
        },

        // bodyErrors() {
        //     const errors = [];
        //     if (!this.$v.body.$dirty) return errors;
        //     !this.$v.body.required && errors.push('Body is required.');
        //     return errors;
        // },
    },

    async mounted() {
        const { shopperId } = this.$router.history.current.params;

        const success = await this.fetchShopper(shopperId);

        if (!success) {
            this.handleCreateNew();
        }

        this.fetchShoppers();
        this.fetchEnvironments();
    },
    data: () => ({
        shopper: {
            id: '',
            title: '',
            body: '',
            environmentid: '',
            resources: [],
            tags: [],
            links: [],
        },
        customToolbar: [
            ['bold', 'italic', 'underline'],
            [{ list: 'ordered' }, { list: 'bullet' }],
        ],
        saved: true,
        isLoading: false,
        isLoadingMessage: '',
    }),
    validations: {
        shopper: {
            title: {
                required,
            },
            // environmentid: {
            //     required,
            // },
        },
    },
    methods: {
        ...mapActions('shoppers', [
            'fetchShopper',
            'fetchShoppers',
            'upsertShopper',
            'deleteShopper',
        ]),
        ...mapActions('environments', ['fetchEnvironments']),

        handleCreateNew() {
            const { params, query } = this.$router.history.current;

            if (query?.type === 'new' && uuidValidateV4(params.shopperId)) {
                this.shopper.id = params.shopperId;
            } else {
                // None valid!
                this.$router.push({ name: 'ViewShoppers' });
            }
        },

        addImage(file) {
            // TODO: Delete existing image before replacing
            this.shopper.resources = [
                {
                    id: shortid.generate(),
                    url: URL.createObjectURL(file),
                    title: file.name,
                    type: file.type,
                },
            ];
        },

        removeImage() {
            // TODO: delete existing image
            this.shopper.resources = [];
        },

        removeTags(item) {
            this.shopper.tags.splice(this.shopper.tags.indexOf(item), 1);
            this.shopper.tags = [...this.shopper.tags];
        },

        addLink() {
            this.shopper.links.push({ title: '', url: '' });
        },

        removeLink(index) {
            this.shopper.links.splice(index, 1);
        },

        async onSave() {
            this.$v.$touch();

            this.isLoading = true;
            const { id, resources } = this.shopper;
            const { query } = this.$router.history.current;

            if (!this.$v.$invalid && id) {
                if (resources[0]?.url.includes('blob:')) {
                    this.isLoadingMessage = `Uploading Image...`;

                    const config = { responseType: 'blob' };
                    const blob = await axios.get(resources[0].url, config);
                    const file = new File([blob.data], resources[0].title);
                    const url = await uploadResource(file);

                    if (url) {
                        this.shopper.resources[0] = {
                            ...this.shopper.resources[0],
                            url,
                        };
                    }
                }

                await this.upsertShopper(this.shopper);

                if (query?.type === 'new') {
                    this.$router.replace({ query: null });
                }
            }

            this.isLoading = false;
            this.isLoadingMessage = '';
        },
        async onDelete() {
            const success = await this.deleteShopper(this.shopper.id);

            if (success) {
                this.$router.push({ name: 'ViewShoppers' });
            }
        },
    },
    watch: {
        shopper: {
            immediate: false,
            handler: function(n) {
                this.saved = isEqual(this.storedShopper, n);
            },
            deep: true,
        },
        storedShopper: {
            immediate: false,
            handler(n) {
                if (n) {
                    this.shopper = cloneDeep(n);
                }
            },
        },
    },
};
</script>

<style>
#editor {
    height: 200px;
}
</style>
