import axios from 'axios';
import CONFIG from '@/../config.json';
import {
    GetAuthorisationHeaderGroup,
    GetBearerToken,
} from './assetxphelpers.js';

import unionBy from 'lodash/unionBy';

const api = axios.create({
    baseURL: CONFIG.platformEndpoints.assetxpapi,
});

export const EnvironmentState = {
    namespaced: true,
    state: {
        environments: [],
    },

    mutations: {
        setEnvironment(state, payload) {
            state.environments = unionBy([payload], state.environments, 'id');
        },
        setEnvironments(state, payload) {
            state.environments = payload;
        },
    },
    actions: {
        async fetchEnvironment({ commit }, environmentId) {
            try {
                const { data, status } = await api.get(
                    `storeviewer/environments/get/${environmentId}`,
                    GetAuthorisationHeaderGroup(),
                );
                commit('setEnvironment', data);

                if (status === 200) {
                    return true;
                }

                throw new Error('No content');
            } catch (e) {
                return false;
            }
        },
        async fetchEnvironments({ commit }) {
            try {
                const { data } = await api.get(
                    `storeviewer/environments/all`,
                    GetAuthorisationHeaderGroup(),
                );
                commit('setEnvironments', data);
            } catch (e) {
                // empty
            }
        },
        async upsertEnvironment({ commit }, environment) {
            try {
                const { data } = await api.post(
                    `storeviewer/environments/upsert`,
                    environment,
                    GetAuthorisationHeaderGroup(),
                );

                commit('setEnvironment', data);

                return true;
            } catch (e) {
                return false;
            }
        },
        async deleteEnvironment({ commit }, environmentId) {
            try {
                await api.get(
                    `storeviewer/environments/delete/${environmentId}`,
                    GetAuthorisationHeaderGroup(),
                );

                return true;
            } catch (e) {
                return false;
            }
        },
    },
    getters: {
        getEnvironment: (state, getters) => id => {
            return state.environments.find(obj => obj.id === id);
        },
    },
};
