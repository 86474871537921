<template>
    <div></div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'TouchpointVisualizer',
    components: {},
    props: {
        touchpoints: Array,
        isActive: false,
        babylonPanelIdentifier: '',
        waypoint: Object,
    },
    computed: {
        ...mapGetters('sessionState', ['GetBabPnl']),
    },

    mounted: () => {},
    data: () => ({
        waypointVisibilityDistance: 10,
    }),
    methods: {
        SpawnTouchpointVisualizers() {
            this.ClearTouchpointVisualizers();
            console.log('Creating touchpoint markers');
            var inst = this.GetBabPnl(this.babylonPanelIdentifier);

            var foundUITexture = inst.scene.textures.filter(
                item => item.name == 'UI',
            );
            console.log('Found textures');
            console.log(foundUITexture);
            var mainUI = foundUITexture[0];
            if (foundUITexture.length == 0) {
                mainUI = BABYLON.GUI.AdvancedDynamicTexture.CreateFullscreenUI(
                    'UI',
                    true,
                );
            }

            var sphereMat = new BABYLON.StandardMaterial(
                'sphereMat',
                inst.scene,
            );
            sphereMat.alpha = 0;

            let currentWaypointPOS = new BABYLON.Vector3(
                this.waypoint.pos.x,
                this.waypoint.pos.y,
                this.waypoint.pos.z,
            );

            console.log('Creating visualizers: ' + this.touchpoints.length);

            for (let i = 0; i < this.touchpoints.length; i++) {
                let compareTPPOS = new BABYLON.Vector3(
                    this.touchpoints[i].pos.x,
                    this.touchpoints[i].pos.y,
                    this.touchpoints[i].pos.z,
                );

                let distance = BABYLON.Vector3.Distance(
                    currentWaypointPOS,
                    compareTPPOS,
                );

                if (distance > this.waypointVisibilityDistance) {
                    continue;
                }

                if (this.touchpoints[i].dir != null) {
                    let dirVector = currentWaypointPOS
                        .subtract(compareTPPOS)
                        .normalize();
                    let dotProd = BABYLON.Vector3.Dot(
                        dirVector,
                        new BABYLON.Vector3(
                            this.touchpoints[i].dir.x,
                            this.touchpoints[i].dir.y,
                            this.touchpoints[i].dir.z,
                        ),
                    );

                    if (dotProd < 0.2) {
                        continue;
                    }
                }

                let sphere = BABYLON.Mesh.CreateSphere(
                    'Sphere_' + this.touchpoints[i].id,
                    16,
                    0.5,
                    inst.scene,
                );
                // Move the sphere upward 1/2 its height
                sphere.position = new BABYLON.Vector3(
                    this.touchpoints[i].pos.x,
                    this.touchpoints[i].pos.y,
                    this.touchpoints[i].pos.z,
                );

                sphere.isVisible = true;
                sphere.material = sphereMat;

                var wldpanel = new BABYLON.GUI.StackPanel();
                wldpanel.width = '50px';
                wldpanel.height = '50px';
                mainUI.addControl(wldpanel);
                wldpanel.linkWithMesh(sphere);
                wldpanel.isVisible = true;

                var wldbutton = BABYLON.GUI.Button.CreateImageOnlyButton(
                    'but',
                    'https://activstoredev.blob.core.windows.net/test/WA/Insight%20Button.png',
                );
                wldbutton.width = '35px';
                wldbutton.height = '35px';
                wldbutton.cornerRadius = 20;
                wldbutton.thickness = 0;
                wldbutton.color = 'blue';

                wldbutton.onPointerUpObservable.add(() => {
                    this.$emit('onTouchpointChange', this.touchpoints[i]);
                });

                wldpanel.addControl(wldbutton);

                this.$options.spheres.push(sphere);
                this.$options.panels.push(wldpanel);
            }
        },
        ClearTouchpointVisualizers() {
            console.log('Clearing touchpoint markers');
            if (this.$options.spheres == null) {
                this.$options.spheres = [];
                this.$options.panels = [];
            }

            for (let i = 0; i < this.$options.spheres.length; i++) {
                this.$options.spheres[i].dispose();
                this.$options.panels[i].dispose();
            }

            this.$options.spheres = [];
            this.$options.panels = [];
        },
    },
    watch: {
        isActive: {
            handler(isEnabled) {
                if (isEnabled) {
                }
            },
        },

        touchpoints: {
            handler(newVal) {
                if (this.isActive) {
                }
            },
        },

        waypoint: {
            handler(newVal) {
                if (this.isActive) {
                    if (this.touchpoints != null)
                        this.SpawnTouchpointVisualizers();
                }
            },
        },
    },
};
</script>
