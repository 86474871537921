import axios from 'axios';
import CONFIG from '@/../config.json';
import {
    GetAuthorisationHeaderGroup,
    // GetBearerToken,
} from '../store/assetxphelpers.js';

export async function uploadResource(file) {
    const formData = new FormData();

    formData.append('file', file);

    const headers = {
        ...GetAuthorisationHeaderGroup().headers,
        'Content-Type': 'multipart/form-data',
    };

    try {
        const { data } = await axios.post(
            CONFIG.platformEndpoints.assetxpapi +
                'storeviewer/resources/upload',
            formData,
            { headers },
        );

        return data;
    } catch (e) {
        console.log(e);
        return null;
    }
}
