<style scoped>
.main {
    background-image: url('../assets/backgroundBlur.jpg');
    background-size: cover;
}
.container {
    margin-top: 200px;
}
</style>

<template>
    <v-main class="main">
        <navigation-header v-if="loggedIn"></navigation-header>

        <v-container class="container" v-if="loggedIn">
            <v-row justify="center">
                <v-col cols="12" sm="6" md="5" lg="4">
                    <v-card>
                        <v-toolbar color="grey darken-4" dark flat>
                            <v-toolbar-title>New presentation</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                            <form>
                                <v-text-field
                                    v-model="title"
                                    :error-messages="titleErrors"
                                    label="Title"
                                    required
                                    @input="$v.title.$touch()"
                                    @blur="$v.title.$touch()"
                                    hide-details="auto"
                                    class="mb-4 pt-1"
                                ></v-text-field>

                                <!-- TODO: Load from environment state! -->
                                <v-select
                                    v-model="environment"
                                    :items="['7-11']"
                                    :error-messages="environmentErrors"
                                    label="Select an environment"
                                    required
                                    @change="$v.environment.$touch()"
                                    @blur="$v.environment.$touch()"
                                    hide-details="auto"
                                    class="mb-4"
                                ></v-select>

                                <v-select
                                    v-if="templates.length > 0"
                                    :items="templates"
                                    v-model="template"
                                    label="Shopper mission template (optional)"
                                    item-text="name"
                                    hide-details="auto"
                                    clearable
                                    return-object
                                ></v-select>
                            </form>
                        </v-card-text>
                        <v-card-actions class="pa-4">
                            <v-btn color="primary" @click="onCreateNew" block>
                                <v-icon left size="large"
                                    >mdi-square-edit-outline</v-icon
                                >Create new
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
            <v-row class="mt-5" justify="center">
                <v-col cols="12" sm="6" md="5" lg="4">
                    <v-card>
                        <v-toolbar color="grey darken-4" dark flat>
                            <v-toolbar-title
                                >Recent presentations
                            </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="pt-1">
                            <v-select
                                :items="getProjects"
                                :label="
                                    isProjectsLoading
                                        ? 'Loading...'
                                        : 'Select a projects'
                                "
                                item-text="title"
                                item-value="id"
                                @change="onRecentSelect"
                                hide-details="auto"
                            ></v-select>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <v-container class="container" v-else>
            <v-row justify="center">
                <v-col cols="12" sm="2">
                    <v-btn width="100%" @click="onLoginPress">Login</v-btn>
                </v-col>
            </v-row>
        </v-container>

        <v-dialog
            v-model="isWelcomeVisible"
            width="400"
            persistent
            overlay-opacity="0.9"
            v-if="loggedIn"
        >
            <v-sheet>
                <v-img
                    max-height="190"
                    width="100%"
                    src="../assets/welcomeHeader.jpg"
                ></v-img>
                <div class="pa-5">
                    <h1 class="text-h5 mb-4">Welcome to the 3D store viewer</h1>
                    <p class="text-body-1">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Quisque at condimentum diam. Quisque eget quam ultricies
                        massa pharetra mollis sed ac eros.
                    </p>
                    <p class="text-body-1">
                        Nulla consectetur at erat aliquet tempus.
                    </p>
                    <v-btn
                        color="primary"
                        elevation="2"
                        raised
                        class="mt-3"
                        @click="onWelcomeClose"
                        >Let's start...</v-btn
                    >
                </div>
            </v-sheet>
        </v-dialog>
    </v-main>
</template>

<script>
import { mapMutations, mapActions, mapGetters, mapState } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { v4 as uuidv4 } from 'uuid';
import router from '../router';
import { LogIn } from '@/js/authentication/authentication';

import NavigationHeader from '../components/Global/NavigationHeader';

export default {
    name: 'Dashboard',
    components: {
        NavigationHeader,
    },
    props: {},
    validations: {
        title: { required },
        environment: { required },
    },
    data: () => ({
        title: '',
        environment: null,
        template: {},
        isProjectsLoading: true,
    }),
    async mounted() {
        await this.fetchProjectMarkers();
        await this.fetchTemplates();

        this.isProjectsLoading = false;
    },
    computed: {
        ...mapState('user', ['isWelcomeVisible']),
        ...mapState('authentication', ['loggedIn']),
        ...mapGetters('templates', ['getTemplates']),
        ...mapGetters('projects', ['getProjects']),

        templates() {
            return this.getTemplates(this.environment);
        },

        loggedIn() {
            return this.$store.state.authentication.loggedIn;
        },

        titleErrors() {
            const errors = [];
            if (!this.$v.title.$dirty) return errors;
            !this.$v.title.required && errors.push('Title is required.');
            return errors;
        },

        environmentErrors() {
            const errors = [];
            if (!this.$v.environment.$dirty) return errors;
            !this.$v.environment.required &&
                errors.push('Enviroment is required');
            return errors;
        },
    },
    methods: {
        ...mapMutations('user', ['hideWelcome']),
        ...mapMutations('authentication', ['logIn', 'setUserName']),
        ...mapActions('projects', ['fetchProjectMarkers', 'upsertProject']),
        ...mapActions('templates', ['fetchTemplates']),

        // validate() {
        //     this.$refs.form.validate();
        // },
        async onCreateNew() {
            this.$v.$touch();

            // TODO: Only allow this to be triggered once
            // TODO: Add loader until returned
            // TODO: Validate againt the same title?

            if (!this.$v.$invalid) {
                const id = uuidv4();

                const success = await this.upsertProject({
                    ...this.template?.project,
                    id,
                    title: this.title,
                    environment: this.environment,
                });

                if (success) {
                    router.push(`/project/${id}`);
                } else {
                    // console.log('error');
                }
            }
        },
        onRecentSelect(id) {
            router.push(`/project/${id}`);
        },
        onWelcomeClose() {
            this.hideWelcome();
        },
        onLoginPress() {
            this.logIn();
            this.setUserName('Demo user');
        },
    },
    watch: {},
};
</script>
