<template>
  <div>
    <h1>Signing in...</h1>
  </div>
</template>

<script>
import { SigninRedirect } from "../../js/authentication/authentication";

export default {

  mounted() {
    var theAccessCodeExistsInTheQueryString = this.$route.query.code
    if(theAccessCodeExistsInTheQueryString)
      SigninRedirect()
    else
      this.$router.push('/')
  },

  computed: {

    loggedIn() {
      return this.$store.state.authentication.loggedIn
    }

  },

  methods: {
    getCookie(cname) {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');
      for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    }

  },
  watch:
  {
    loggedIn: {
           
      handler(newVal, oldVal) {
          
        if(newVal)
        {
          console.log("Logged in");
          this.$router.push(this.getCookie("redirectAuthUrl"))
        }
      }
    }
  }
};
</script>
