<style scoped>
.toolbar {
    display: flex;
    position: absolute;
    width: 100%;
    bottom: 30px;
    /* align-content: center; */
    justify-content: center;
    z-index: 1;
}
.button--active {
    background-color: #26a69a !important;
}
.button--active .v-icon {
    color: #fff !important;
}
.shopper-mini-profile {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
}
</style>

<template>
    <fragment>
        <navigation-header v-if="loggedIn">
            <template v-slot:navigationLeft>
                <v-btn small text class="pa-6" to="/">
                    <v-icon left>
                        mdi-arrow-left
                    </v-icon>
                    Dashboard
                </v-btn>
            </template>

            <v-btn small text class="pa-6" @click="onSettingsClick">
                <v-icon left>
                    mdi-cog
                </v-icon>
                Project Settings
            </v-btn>
        </navigation-header>

        <!-- TODO: Can we async load the following in? -->

        <welcome-dialog
            :isVisible="!shopper.id && presentationMode"
            title="Welcome to the 3D store viewer presentation"
            body="To begin, press 'store overview' or 'select a shopper' below to navigate through the store as a shopper."
        />

        <insight-editor
            v-if="insightVisible && editMode"
            :id="activeMarkerId"
            :selectedShopperId="shopper.id"
            @closed="onInsightModalClose"
            @deleted="onMarkerDelete"
        />

        <insight-viewer
            v-if="insightVisible && !editMode"
            :id="activeMarkerId"
            @closed="onInsightModalClose"
        />

        <project-settings
            v-if="settingsVisible"
            @closed="onSettingsModalClose"
        />

        <shopper-mini-profile
            v-if="shopper.id"
            :title="shopper.title"
            :imageSrc="shopper.resources[0].url"
            :tags="shopper.tags"
            class="shopper-mini-profile"
            @clear-shopper="onShopperClear"
        />

        <v-dialog
            v-model="overviewVisible"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card dark>
                <v-toolbar color="grey darken-4" dark>
                    <v-toolbar-title class="title">
                        Store overview
                    </v-toolbar-title>
                    <v-btn icon @click="overviewVisible = false" color="white">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>

                <!-- <v-layout fill-height> -->
                <img
                    src="https://axpsxpdev.blob.core.windows.net/storeviewer/67cee21d-eab6-4f19-887a-c71796a1e1fa.png"
                    style="width: 100%; height: calc(64px - 100%); background-size: contain; background-position: center center;"
                />
                <!-- </v-layout> -->
            </v-card>
        </v-dialog>

        <!-- :color="editMode ? 'white' : ''" -->

        <navigation-footer :overlay="!shopper.id">
            <template v-slot:navigationLeft v-if="loggedIn">
                <v-btn
                    large
                    class="pa-6"
                    @click="onEditModeClick"
                    :dark="!editMode"
                >
                    <v-icon>mdi-square-edit-outline</v-icon>
                </v-btn>
            </template>

            <v-btn large text class="pa-6" @click="overviewVisible = true" dark>
                <v-icon left>mdi-store</v-icon>
                Store overview
            </v-btn>
            <shopper-selector
                ref="shopperSelector"
                @selected-shopper="onShopperSelect"
            />

            <template v-slot:navigationRight>
                <store-viewer-navigation
                    v-if="shopper.id"
                    :editMode="editMode"
                    :selectedShopperId="shopper.id"
                    @reset="handleResetJourney"
                />
            </template>
        </navigation-footer>

        <v-snackbar
            v-model="error"
            :vertical="true"
            :timeout="errorTimeout"
            color="pink darken-3"
        >
            {{ errorMessage }}

            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="error = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </fragment>
</template>

<script>
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex';

import shortid from 'shortid';
import { Fragment } from 'vue-fragment';

import NavigationHeader from '../Global/NavigationHeader';
import InsightEditor from './InsightEditor';
import InsightViewer from './InsightViewer';
import ProjectSettings from './ProjectSettings';
import StoreViewerNavigation from './StoreViewerNavigation';
import ShopperSelector from './ShopperSelector';
import ShopperMiniProfile from './ShopperMiniProfile';
import NavigationFooter from '../Global/NavigationFooter';
import WelcomeDialog from './WelcomeDialog.vue';

export default {
    name: 'StoreViewer',
    components: {
        Fragment,
        NavigationHeader,
        InsightEditor,
        InsightViewer,
        ProjectSettings,
        StoreViewerNavigation,
        ShopperSelector,
        ShopperMiniProfile,
        NavigationFooter,
        WelcomeDialog,
    },
    props: {
        waypointId: {
            default: '',
            type: String,
        },
    },
    computed: {
        ...mapState('authentication', ['loggedIn']),
        ...mapGetters('sessionState', {
            GetBabPnl: 'GetBabPnl',
            firstWaypoint: 'getFirstWaypoint',
        }),
        ...mapGetters('projects', ['getProjectMarkers']),
        // ...mapGetters('environments', ['getEnvironment']),

        storedMarkers() {
            const { projectId } = this.$route.params;

            if (!projectId) {
                return null;
            }

            return this.getProjectMarkers(projectId, {
                waypoint: this.waypointId,
                shopperId: this.shopper.id,
            });
        },

        shopperId() {
            return this.shopper.id;
        },
    },

    mounted() {
        this.clearAllMarkers();
        this.createClickHandler();
        this.generateMarkers();

        if (this.$router.history.current.query?.presentation == 'on') {
            this.presentationMode = true;
        }
    },

    data: () => ({
        presentationMode: false,
        activeMarkerId: null,
        editMode: false,
        insightVisible: false,
        settingsVisible: false,
        overviewVisible: false,
        shopper: {
            id: '',
        },
        error: false,
        errorMessage: '',
        errorTimeout: 2000,
    }),

    methods: {
        ...mapActions('projects', ['upsertMarker']),
        ...mapMutations('sessionState', ['SetJumpToWaypoint']),

        createClickHandler() {
            const { scene } = this.GetBabPnl('MainPanel');
            var camera = scene.activeCamera;

            scene.onPointerDown = () => {
                // Trigger on ctrl + click
                if (this.editMode) {
                    this.onCreateNewMarker();
                }

                // Cancel any current animations
                scene.stopAnimation(camera);
            };
        },

        onSettingsClick() {
            this.settingsVisible = !this.settingsVisible;
        },

        onEditModeClick() {
            if (this.shopper.id) {
                this.editMode = !this.editMode;
            } else {
                this.error = true;
                this.errorMessage =
                    'Select a shopper before creating a point of interest';
            }
        },

        onSettingsModalClose() {
            this.settingsVisible = false;
        },

        onCreateNewMarker() {
            const { scene } = this.GetBabPnl('MainPanel');
            const id = shortid.generate();

            const pickResult = scene.pick(
                scene.pointerX,
                scene.pointerY,
                mesh => {
                    return mesh;
                },
            );

            const { x, y, z } = pickResult.pickedPoint;

            const markersCount = this.storedMarkers.length;
            const order = markersCount > 0 ? markersCount : 0;

            this.upsertMarker({
                id,
                waypoint: this.waypointId,
                points: { x, y, z },
                order,
            });

            this.generateMarker(id, { x, y, z });

            this.insightVisible = true;
            this.activeMarkerId = id;
        },

        onMarkerClick(id) {
            this.insightVisible = true;
            this.activeMarkerId = id;
        },

        onInsightModalClose() {
            this.insightVisible = false;
            this.activeMarkerId = null;
            this.editMode = false;
        },

        onMarkerDelete() {
            this.deleteMarker(this.activeMarkerId);
            this.onInsightModalClose();
        },

        generateMarker(id, { x, y, z }) {
            const { scene } = this.GetBabPnl('MainPanel');

            const pointPosition = new BABYLON.Vector3(x, y, z);

            let sphereMat = new BABYLON.StandardMaterial(
                `sphereMat_${id}`,
                scene,
            );
            sphereMat.alpha = 0;

            let sphere = BABYLON.Mesh.CreateSphere(
                `sphere_${id}`,
                16,
                0.5,
                scene,
            );

            sphere.position = pointPosition;
            sphere.isVisible = true;
            sphere.material = sphereMat;

            let advancedTexture = BABYLON.GUI.AdvancedDynamicTexture.CreateFullscreenUI(
                'UI',
            );

            let button = new BABYLON.GUI.Button.CreateSimpleButton(
                `button_${id}`,
                '+',
            );
            button.width = '70px';
            button.height = '70px';
            button.thickness = 6;
            button.background = '#ffffff';
            button.color = '#26A69A';
            button.position = pointPosition;
            button.cornerRadius = 55;
            button.shadowColor = 'rgba(0,0,0,0.3)';
            button.shadowBlur = 20;
            button.fontSize = 30;
            button.fontWeight = 100;

            advancedTexture.addControl(button);

            button.onPointerClickObservable.add(() => this.onMarkerClick(id));
            button.linkWithMesh(sphere);

            this.$options.spheres.push(sphere);
            this.$options.buttons.push(button);
        },

        generateMarkers() {
            if (this.storedMarkers) {
                this.storedMarkers.forEach(({ id, points }) => {
                    this.generateMarker(id, points);
                });
            }
        },

        deleteMarker(id) {
            this.$options.spheres.forEach((item, i) => {
                if (item.id === `sphere_${id}`) {
                    this.$options.spheres[i].dispose();
                }
            });
            this.$options.buttons.forEach((item, i) => {
                if (item.name === `button_${id}`) {
                    this.$options.buttons[i].dispose();
                }
            });
        },

        clearAllMarkers() {
            console.log('Clearing points');

            if (this.$options.spheres == null) {
                this.$options.spheres = [];
                this.$options.buttons = [];
            }

            this.$options.spheres.forEach((_, i) => {
                this.$options.spheres[i].dispose();
                this.$options.buttons[i].dispose();
            });

            this.$options.spheres = [];
            this.$options.buttons = [];
        },

        onShopperSelect(shopperObj) {
            this.shopper = shopperObj;
        },

        onShopperClear() {
            this.$refs.shopperSelector.onClear();

            this.handleResetJourney();
        },

        handleResetJourney() {
            const { scene } = this.GetBabPnl('MainPanel');
            const { id, pos, euler } = this.firstWaypoint;

            this.SetJumpToWaypoint(id);

            let camera = scene.activeCamera;

            camera.rotation = new BABYLON.Vector3(
                BABYLON.Tools.ToRadians(euler.x),
                BABYLON.Tools.ToRadians(euler.y),
                0,
            );
        },
    },
    watch: {
        waypointId: {
            immediate: true,
            handler(newValue, oldValue) {
                if (oldValue != null) {
                    this.clearAllMarkers();
                    this.generateMarkers();
                }
            },
        },
        shopperId: {
            immediate: true,
            handler(newValue, oldValue) {
                if (oldValue != null) {
                    this.clearAllMarkers();
                    this.generateMarkers();
                }
            },
        },
    },
};
</script>
