<style scoped>
.close-button {
    position: absolute;
    top: 5px;
    right: 5px;
}
.title {
    flex: 1;
}
.title-field {
    font-size: 1.25rem;
}
.image-grid {
    position: relative;
    flex: 1;
    height: 100%;
    max-height: 372px;
    overflow-y: scroll;
    background-color: #e1e1e1;
}
.upload-btn {
    justify-self: flex-start;
    pointer-events: auto !important;
    cursor: pointer !important;
}
.upload-btn__text {
    display: block;
}
.square-block {
    position: relative;
    width: 100%;
    padding-top: 100%;
    position: relative;
}
.square-block__inner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    /* border: 1px solid white; */
}
.image-cloud {
    position: absolute;
    bottom: 5px;
    right: 6px;
}
.card-actions {
    border-top: 1px solid #e1e1e1;
    justify-content: space-between;
}
</style>

<template>
    <!-- <fragment> -->
    <v-dialog v-model="isVisible" width="800" persistent overlay-opacity="0.8">
        <v-card>
            <v-toolbar color="grey darken-4" dark flat>
                <v-toolbar-title class="title">
                    <!-- TODO: Add validation -->
                    <v-text-field
                        label="Add your title here..."
                        v-model="marker.content.title"
                        hide-details="auto"
                        single-line
                        background-color="transparent"
                        class="title-field"
                    />
                </v-toolbar-title>
                <v-btn icon @click="onClose" color="white">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-container pa-0>
                <v-row no-gutters>
                    <v-col md="5">
                        <div class="image-grid no-gutters">
                            <draggable v-model="marker.content.resources">
                                <transition-group class="row no-gutters">
                                    <template
                                        v-for="(item, index) in marker.content
                                            .resources"
                                    >
                                        <v-col cols="12" sm="4" :key="index">
                                            <div class="square-block">
                                                <v-img
                                                    height="100%"
                                                    width="100%"
                                                    cover
                                                    class="square-block__inner"
                                                    :src="item.url"
                                                >
                                                    <template
                                                        v-slot:placeholder
                                                    >
                                                        <v-row
                                                            class="fill-height ma-0"
                                                            align="center"
                                                            justify="center"
                                                        >
                                                            <v-progress-circular
                                                                indeterminate
                                                                color="grey lighten-5"
                                                                :size="20"
                                                                :width="3"
                                                            ></v-progress-circular>
                                                        </v-row>
                                                    </template>
                                                </v-img>

                                                <v-icon
                                                    class="image-cloud"
                                                    small
                                                    color="primary"
                                                    v-if="
                                                        !item.url.includes(
                                                            'blob:',
                                                        )
                                                    "
                                                    >mdi-cloud-check</v-icon
                                                >
                                            </div>
                                        </v-col>
                                    </template>
                                </transition-group>
                            </draggable>
                        </div>
                    </v-col>
                    <v-col md="7">
                        <v-layout pt-3 px-3 column align-content-end>
                            <vue-editor
                                id="editor"
                                v-model="marker.content.body"
                                :editor-toolbar="customToolbar"
                                class="pb-3"
                            ></vue-editor>

                            <v-select
                                v-model="marker.shoppers"
                                :items="shoppers"
                                chips
                                clearable
                                label="Display with the following shopper(s)"
                                item-text="title"
                                item-value="id"
                                :return-object="false"
                                hide-details="auto"
                                multiple
                                class="mb-5"
                                offset-lg10
                            >
                                <template v-slot:item="{ item }">
                                    <v-avatar
                                        color="primary"
                                        size="28"
                                        class="mr-2"
                                    >
                                        <v-img
                                            v-if="item.resources[0]"
                                            :src="item.resources[0].url"
                                            :alt="item.title"
                                            cover
                                        />
                                        <v-icon v-else dark>
                                            mdi-account-circle
                                        </v-icon>
                                    </v-avatar>
                                    <span class="body-2">{{ item.title }}</span>
                                </template>

                                <!-- TODO: Add validation -->

                                <template
                                    v-slot:selection="{
                                        attrs,
                                        item,
                                        select,
                                        selected,
                                    }"
                                >
                                    <v-chip
                                        pill
                                        v-bind="attrs"
                                        :input-value="selected"
                                        close
                                        @click="select"
                                        @click:close="removeTags(item)"
                                        small
                                        color="primary"
                                    >
                                        <!-- <v-avatar left>
                                            <v-img
                                                v-if="item.resources[0]"
                                                :src="item.resources[0].url"
                                            ></v-img>
                                        </v-avatar> -->
                                        <strong>{{ item.title }}</strong>
                                    </v-chip>
                                </template>
                            </v-select>
                        </v-layout>
                    </v-col>
                </v-row>
                <v-card-actions class="card-actions">
                    <label
                        for="upload-file"
                        class="v-btn v-btn--outlined v-btn--text theme--light v-size--default upload-btn"
                    >
                        <input
                            id="upload-file"
                            type="file"
                            ref="file"
                            multiple="multiple"
                            accept=".jpg, .jpeg, .png"
                            @change="setResources($event.target.files)"
                            hidden
                        />
                        <span class="v-btn__content">Add images</span>
                        <v-icon
                            aria-label="Add image"
                            role="img"
                            aria-hidden="false"
                            medium
                            class="ml-2"
                        >
                            mdi-image-plus
                        </v-icon>
                    </label>
                    <div>
                        <v-btn text @click="onDelete" outlined>
                            Delete
                        </v-btn>
                        <v-btn
                            color="primary"
                            :disabled="isLoading || saved"
                            @click="onSave"
                            class="ml-2"
                        >
                            <v-progress-circular
                                indeterminate
                                color="primary"
                                :size="20"
                                :width="3"
                                v-if="isLoading"
                            ></v-progress-circular>
                            <span v-else>
                                {{ saved ? 'Saved' : 'Save' }}
                            </span>
                        </v-btn>
                    </div>
                </v-card-actions>
            </v-container>
        </v-card>

        <v-snackbar
            :timeout="-1"
            :value="!!isLoadingMessage"
            color="primary"
            rounded="pill"
        >
            <p class="text-center mb-0">{{ isLoadingMessage }}</p>
        </v-snackbar>
    </v-dialog>
    <!-- </fragment> -->
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { VueEditor } from 'vue2-editor';
import shortid from 'shortid';
import axios from 'axios';
import Draggable from 'vuedraggable';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';

import { uploadResource } from '../../actions/resourcesActions';

export default {
    name: 'InsightEditor',
    components: {
        VueEditor,
        Draggable,
    },

    props: {
        id: {
            type: [String, null],
            default: '',
        },
        selectedShopperId: String,
    },

    computed: {
        ...mapState('projects', ['projects']),
        ...mapState('shoppers', ['shoppers']),
        ...mapGetters('projects', ['getProjectMarker']),

        storedMarker() {
            const { projectId } = this.$route.params;
            return this.getProjectMarker(projectId, this.id);
        },
    },

    // TODO: Add unsaved resources field
    data: () => ({
        isVisible: true,
        marker: {
            content: {
                title: '',
                body: '',
                resources: [],
            },
            shoppers: [],
        },
        //deletedResources: [],
        customToolbar: [
            ['bold', 'italic', 'underline'],
            [{ list: 'ordered' }, { list: 'bullet' }],
        ],
        saved: true,
        isLoading: false,
        isLoadingMessage: '',
    }),

    methods: {
        ...mapActions('projects', ['upsertMarker', 'deleteMarker']),

        onTitleChange(value) {
            this.marker.content.title = value;
        },

        setResources(fileList) {
            const resources = Object.keys(fileList).map((value, key) => ({
                id: shortid.generate(),
                url: URL.createObjectURL(fileList[key]),
                title: fileList[key].name,
                type: fileList[key].type,
            }));

            this.marker.content.resources = resources.concat(
                this.marker.content.resources,
            );
        },

        removeTags(item) {
            this.marker.shoppers.splice(this.marker.shoppers.indexOf(item), 1);
            this.marker.shoppers = [...this.marker.shoppers];
        },

        // handleReset() {
        //     var self = this;

        //     Object.keys(this.marker.content).forEach(function(key) {
        //         self.marker.content[key] = '';
        //     });
        // },

        onClose() {
            this.$emit('closed');
        },

        async onSave() {
            this.isLoading = true;
            const { resources } = this.marker.content;
            const localImages = resources.filter(o => o.url.includes('blob:'));

            // 1. Upload temporary marker
            await this.upsertMarker(this.marker);

            // 2. TODO: Remove deleted resource from remote storage

            // 3. Process unsaved resources
            for await (const [i, obj] of localImages.entries()) {
                this.isLoadingMessage = `Uploading Images(s) ${i}/${localImages.length}`;

                const config = { responseType: 'blob' };
                const blob = await axios.get(obj.url, config);
                const file = new File([blob.data], obj.title);
                const url = await uploadResource(file);

                if (url) {
                    const updatedResource = {
                        ...resources[i],
                        url,
                    };

                    this.marker.content.resources = this.marker.content.resources.map(
                        obj => {
                            if (obj.id !== updatedResource.id) return obj;
                            return updatedResource;
                        },
                    );

                    await this.upsertMarker(this.marker);
                }
            }
            this.isLoading = false;
            this.isLoadingMessage = '';

            // TODO: remove corrupt local blob urls from server!
        },

        onDelete() {
            this.deleteMarker(this.id);
            this.$emit('deleted');
        },
    },
    watch: {
        marker: {
            immediate: false,
            handler: function(n) {
                this.saved = isEqual(this.storedMarker, n);
            },
            deep: true,
        },
        storedMarker: {
            immediate: true,
            handler(n) {
                const markerClone = cloneDeep(n);

                // TODO: Clean the following up
                this.marker = {
                    ...markerClone,
                    content: markerClone?.content || this.marker.content,
                    shoppers:
                        markerClone?.shoppers.length === 0
                            ? [this.selectedShopperId]
                            : markerClone?.shoppers,
                };
            },
        },
    },
};
</script>

<style>
#editor {
    height: 400px;
}
</style>
