<template>
    <fragment>
        <v-main>
            <stage-environment
                v-if="gotPackageData"
                ref="stageEnvironment"
            ></stage-environment>
        </v-main>
        <loading-screen />
    </fragment>
</template>

<script>
import axios from 'axios';
import config from '../../../config.json';
import LoadingScreen from '../LoadingScreen';
import StageEnvironment from './Types/StageEnvironment.vue';
import router from '../../router';
import { mapActions, mapMutations } from 'vuex';
import { mapState, mapGetters } from 'vuex';

import { Fragment } from 'vue-fragment';

export default {
    name: 'Environment',

    components: {
        StageEnvironment,
        LoadingScreen,
        Fragment,
    },
    computed: {
        ...mapState('sessionState', ['package']),
        ...mapState('authentication', ['loggedIn', 'subid']),
        ...mapGetters('projects', ['getEnvironment']),

        project() {
            const { projectId } = this.$route.params;
            return this.getEnvironment(projectId);
        },
    },

    data: () => ({
        gotPackageData: false,
        presentation: false,
        //
    }),
    mounted: function() {
        const { presentation } = this.$router.history.current.query;
        const presentationMode = presentation === 'on';

        if (presentationMode || this.loggedIn) {
            this.presentation = presentationMode;
            this.setup(presentationMode);
        } else {
            this.$router.push('/');
        }
    },
    methods: {
        ...mapMutations('sessionState', ['SetPackageData', 'SetStageData']),
        ...mapActions('projects', ['fetchProject']),
        ...mapActions('environments', ['fetchEnvironment']),

        async setup() {
            const { projectId } = this.$route.params;

            await this.fetchProject(projectId);

            const { environment, userid } = this.project;

            console.log(userid == this.subid);

            if (!environment || (this.loggedIn && userid !== this.subid)) {
                router.push(`/`);
                return;
            }

            this.fetchEnvironment(environment);

            var url =
                config.platformEndpoints.coreblobstorage +
                'packagesv2/' +
                environment +
                '.json';

            //console.log('Getting package at url: ' + url);

            axios.get(url).then(response => {
                this.SetPackageData({
                    id: environment,
                    data: response.data,
                });
                this.gotPackageData = true;

                if (response.data.stages.length > 0) {
                    this.SetStageData(response.data.stages[0]);
                }
            });
        },
    },
};
</script>
