import store from '@/store/index.js'
import { UserManager } from "oidc-client"
import HandleError from "../errorHandler"
import Config from '@/../config.json'

var userManagerSingleton = createUserManager()

export function LogIn() {
  
  if(window.webpackHotUpdate || Config.platformEndpoints.authentication == "" || !Config.appOptions.forceEnableAuth)
  {
    store.dispatch('authentication/setLoginStateAsDev');
  }
  else
  {
    return userManagerSingleton.signinRedirect()
    .catch(e => {
      HandleError(e)
      throw Error("Unable to log in")
    })
  }
}

export function SigninRedirect() {


  new UserManager({ response_mode: "query" })
    .signinRedirectCallback()
      .then(() => store.dispatch('authentication/updateLoggedInStatus'))
      //.then(() => window.location = "/")
      .catch(e => HandleError(e));
}

export async function GetUser() {
  var user = await userManagerSingleton.getUser()

  if(!user) return null

  var applicationClaims = []
  var requiredClaim = Config.authentication.clientId

  if(Array.isArray(user.profile.application)){
    applicationClaims = user.profile.application.filter(x=> x === requiredClaim || x.startsWith(requiredClaim+":"));
  }
  else{
    applicationClaims.push(user.profile.application)
  }

  var userHasAccess = applicationClaims && applicationClaims.includes(requiredClaim)
  store.commit('authentication/userHasAccessToApplication', userHasAccess)
  if(!userHasAccess) {
    console.error("User does not have correct claim", applicationClaims)
    return user
  }

  var userCanEditAssets = applicationClaims && applicationClaims.includes(requiredClaim+":canEditAssets")
  store.commit('authentication/canEditAssets', userCanEditAssets)
    
  var userCanViewNPD = applicationClaims && applicationClaims.includes(requiredClaim+":history")
  store.commit('authentication/canViewNPD', userCanViewNPD)

  var userCanManageTaxonomies = applicationClaims && applicationClaims.includes(requiredClaim+":canManageTaxonomies")
  store.commit('authentication/canManageTaxonomies', userCanManageTaxonomies)

  return user
}

export function GetUserAccessToken() {
  return GetUser().then(user => {
    if(user)
      console.info("Access token expires in", user.expires_in)
    return user ? user.access_token : ""
  })
}

export function LogOut() {
  return store.dispatch('authentication/updateLoggedInStatus')
    .then(() => userManagerSingleton.signoutRedirect())
}

export function ChangePassword() {
  var url = Config.platformEndpoints.authentication + "/password/change"
  window.open(url,'_blank')
}

export function SigninSilentCallback() {
  userManagerSingleton.signinSilentCallback()
    .then()
    .catch(error => console.error(error))
}

export function HandleUnauthorised() {
  LogOut()
}

function createUserManager() {
  var scope = "openid profile " + Config.authentication.assetManagerApiName
  var origin = window.location.origin
  var redirectUri = origin + "/#/callback"
  var silentRenewUri = origin + "/#/silentRenewCallback"

  var config = {
    authority: Config.platformEndpoints.authentication,
    client_id: Config.authentication.clientId,
    redirect_uri: redirectUri,
    response_type: "code",
    scope: scope,
    post_logout_redirect_uri: origin,
    silent_redirect_uri: silentRenewUri,
    automaticSilentRenew: true,
  };

  console.info("Creating user manager")

  var mgr = new UserManager(config)
 
  mgr.events.addUserLoaded(function(){
    // The silent sign in doesn't have access to the same vuex instance as the main page. This forces the state to be updated in the main instance.
    
    store.dispatch('authentication/updateLoggedInStatus')
  });

 

  return mgr;
}