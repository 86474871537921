<style>
#editor {
    height: 300px;
}
</style>

<style scoped>
.close-button {
    position: absolute;
    top: 5px;
    right: 5px;
}
</style>

<template>
    <fragment>
        <v-dialog
            v-model="isVisible"
            width="500"
            persistent
            overlay-opacity="0.8"
        >
            <v-card>
                <v-toolbar color="grey darken-4" dark flat>
                    <v-toolbar-title>Project settings</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="onClose" color="white">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-card-text class="mt-4">
                    <form>
                        <v-text-field
                            v-model="project.title"
                            :error-messages="titleErrors"
                            label="Title"
                            required
                            @input="$v.project.title.$touch()"
                            @blur="$v.project.title.$touch()"
                        ></v-text-field>

                        <v-row>
                            <v-col md="10">
                                <v-text-field
                                    ref="publicLink"
                                    v-model="url"
                                    label="Public link"
                                    readonly
                                ></v-text-field>
                            </v-col>
                            <v-col md="2" class="justify-end">
                                <v-btn
                                    class="mt-3"
                                    outlined
                                    elevation="0"
                                    @click="handleCopyText"
                                >
                                    <v-icon>
                                        mdi-content-copy
                                    </v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </form>
                </v-card-text>

                <v-card-actions class="justify-space-between">
                    <v-btn
                        text
                        @click="onSaveAsTemplate"
                        outlined
                        elevation="0"
                    >
                        {{
                            this.template
                                ? 'Update template'
                                : 'Save as template'
                        }}
                    </v-btn>
                    <div>
                        <v-btn text @click="onDelete" outlined elevation="0">
                            Delete
                        </v-btn>
                        <v-btn
                            color="primary"
                            :disabled="saved"
                            @click="onSave"
                            class="ml-2"
                            elevation="0"
                        >
                            Save
                        </v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="copied" :timeout="3000" color="primary">
            Link copied to clipboard
        </v-snackbar>

        <!-- TODO: Add project button here and control the state -->
    </fragment>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import router from '../../router';
import { Fragment } from 'vue-fragment';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { v4 as uuidv4 } from 'uuid';

export default {
    name: 'ProjectSettings',
    components: {
        Fragment,
    },

    props: {
        id: {
            type: [String, null],
            default: '',
        },
    },
    mixins: [validationMixin],
    validations: {
        project: { title: { required } },
    },
    computed: {
        ...mapGetters('projects', ['getProject']),
        ...mapGetters('templates', { template: 'getConnectedTemplate' }),

        storedProject() {
            const { projectId } = this.$route.params;

            return this.getProject(projectId);
        },

        titleErrors() {
            const errors = [];
            if (!this.$v.project.title.$dirty) return errors;
            // !this.$v.project.title.maxLength &&
            //     errors.push('Title must be at most 100 characters long');
            !this.$v.project.title.required &&
                errors.push('Title is required.');
            return errors;
        },
    },

    async mounted() {
        await this.fetchTemplates();

        this.url = `${window.location.href}?presentation=on`;
    },

    data: () => ({
        isVisible: true,
        project: {},
        saved: true,
        url: '',
        copied: false,
    }),

    methods: {
        ...mapActions('projects', ['upsertProject', 'deleteProject']),
        ...mapActions('templates', ['upsertTemplate', 'fetchTemplates']),

        onTitleChange(value) {
            this.project.title = value;
        },
        handleCopyText() {
            let textToCopy = this.$refs.publicLink.$el.querySelector('input');
            textToCopy.select();
            document.execCommand('copy');
            this.copied = true;
        },
        handleReset() {
            //var self = this;
            //this.title = '';
        },
        onClose() {
            // If not saved. Trigger are you sure?
            this.$emit('closed');
        },
        onSave() {
            this.$v.$touch();

            if (!this.$v.$invalid) {
                this.upsertProject(this.project);
                this.onClose();
            }
        },
        onSaveAsTemplate() {
            // If project previously saved as template then update template.
            // Else generate new ID and create template
            const id = this.template?.id || uuidv4();

            this.upsertTemplate({
                id,
                name: this.project.title,
                project: this.project,
            });
        },
        async onDelete() {
            const success = await this.deleteProject(this.project.id);

            if (success) {
                router.push(`/`);
            } else {
                // TODO: error
            }
        },
    },
    watch: {
        // id: function() {},
        project: {
            immediate: false,
            handler: function(n) {
                this.saved = isEqual(this.storedProject, n);
            },
            deep: true,
        },
        storedProject: {
            immediate: true,
            handler(n) {
                this.project = cloneDeep(n);
            },
        },
    },
};
</script>
