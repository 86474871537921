import axios from 'axios';
import CONFIG from '@/../config.json';
import {
    GetAuthorisationHeaderGroup,
    GetBearerToken,
} from './assetxphelpers.js';
import unionBy from 'lodash/unionBy';

const api = axios.create({
    baseURL: CONFIG.platformEndpoints.assetxpapi,
});

export const ShopperState = {
    namespaced: true,
    state: {
        shoppers: [],
    },

    mutations: {
        setShopper(state, payload) {
            state.shoppers = unionBy([payload], state.shoppers, 'id');
        },
        setShoppers(state, payload) {
            state.shoppers = payload;
        },
    },
    actions: {
        async fetchShopper({ commit }, shopperId) {
            try {
                const { data, status } = await api.get(
                    `storeviewer/shopper/get/${shopperId}`,
                    GetAuthorisationHeaderGroup(),
                );
                commit('setShopper', data);

                if (status === 200) {
                    return true;
                }

                throw new Error('No content');
            } catch (e) {
                return false;
            }
        },
        async fetchShoppers({ commit }) {
            try {
                const { data } = await api.get(
                    `storeviewer/shopper/all`,
                    GetAuthorisationHeaderGroup(),
                );
                commit('setShoppers', data);
            } catch (e) {
                // empty
            }
        },
        async upsertShopper({ commit }, shopper) {
            try {
                const { data } = await api.post(
                    `storeviewer/shopper/upsert`,
                    shopper,
                    GetAuthorisationHeaderGroup(),
                );

                commit('setShopper', data);

                return true;
            } catch (e) {
                return false;
            }
        },
        async deleteShopper({ commit }, shopperId) {
            try {
                await api.get(
                    `storeviewer/shopper/delete/${shopperId}`,
                    GetAuthorisationHeaderGroup(),
                );

                return true;
            } catch (e) {
                return false;
            }
        },
    },
    getters: {
        getShopper: state => id => {
            return state.shoppers.find(obj => obj.id === id);
        },
        allShopperTags: state => {
            const tags = state.shoppers.reduce((acc, val) => {
                return [...acc, ...val.tags];
            }, []);

            const cleanTags = [...new Set(tags)];

            return cleanTags.sort((a, b) => a.localeCompare(b));
        },
        getProjectShoppers: (
            state,
            getters,
            rootState,
            rootGetters,
        ) => projectId => {
            const projectShoppers = rootGetters[
                'projects/getShoppersByProject'
            ](projectId);

            return state.shoppers.filter(obj =>
                projectShoppers.includes(obj.id),
            );
        },
    },
};
