import router from '../router/index';
import orderBy from 'lodash/orderBy';
import unionBy from 'lodash/unionBy';
import axios from 'axios';
import CONFIG from '@/../config.json';
import {
    GetAuthorisationHeaderGroup,
    GetBearerToken,
} from './assetxphelpers.js';
// import { v4 as uuidv4 } from 'uuid';

const api = axios.create({
    baseURL: CONFIG.platformEndpoints.assetxpapi,
});

export const TemplateState = {
    namespaced: true,
    state: {
        templates: [],
    },

    mutations: {
        setTemplates(state, payload) {
            //state.templates = unionBy(payload, state.templates, 'id');
            state.templates = payload;
        },
        setTemplate(state, payload) {
            state.templates = unionBy([payload], state.templates, 'id');
        },
    },
    actions: {
        async fetchTemplates({ commit }) {
            try {
                const { data } = await api.get(
                    `storeviewer/templates/all`,
                    GetAuthorisationHeaderGroup(),
                );
                commit('setTemplates', data);
            } catch (e) {
                // empty
            }
        },
        async upsertTemplate({ commit }, template) {
            try {
                const { data } = await api.post(
                    `storeviewer/templates/upsert`,
                    template,
                    GetAuthorisationHeaderGroup(),
                );

                commit('setTemplate', data);

                return true;
            } catch (e) {
                return false;
            }
        },
    },
    getters: {
        getTemplates: state => environment => {
            return state.templates.filter(
                o => o.project.environment === environment,
            );
        },
        getConnectedTemplate: state => {
            const { projectId } = router.history.current.params;

            return state.templates.find(o => o.project?.id === projectId);
        },
    },
};
