<template>
    <v-layout fill-height="">
        <canvas
            style="height:100%;width:100%"
            tabindex="0"
            class="renderCanvas"
            ref="rendercanvas"
            id="rendercanvas"
        ></canvas>
    </v-layout>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
    name: 'BabylonPanel',
    components: {},
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    computed: {},

    mounted: function() {
        this.$loadScript('https://cdn.babylonjs.com/babylon.js')
            .then(() => {
                this.$loadScript(
                    'https://cdn.babylonjs.com/loaders/babylonjs.loaders.js',
                ).then(() => {
                    this.$loadScript(
                        'https://cdn.babylonjs.com/gui/babylon.gui.min.js',
                    ).then(() => {
                        this.CreateEngine();
                    });
                });
            })
            .catch(() => {
                // Failed to fetch script
            });
    },
    beforeDestroy: function() {
        this.$options.engine.dispose();
        this.RemoveBabylonInstance(this.id);
    },
    data: () => ({}),
    methods: {
        ...mapMutations('sessionState', [
            'AddNewBabylonInstance',
            'RemoveBabylonInstance',
        ]),
        CreateScene() {
            // Create the scene space
            var scene = new BABYLON.Scene(this.$options.engine);

            // Add a camera to the scene and attach it to the canvas

            // Add lights to the scene
            var light1 = new BABYLON.HemisphericLight(
                'light1',
                new BABYLON.Vector3(1, 1, 0),
                scene,
            );
            var light2 = new BABYLON.PointLight(
                'light2',
                new BABYLON.Vector3(0, 1, -1),
                scene,
            );

            // Add and manipulate meshes in the scene
            scene.actionManager = new BABYLON.ActionManager(scene);
            scene.environmentTexture = new BABYLON.CubeTexture(
                'https://activstoredev.blob.core.windows.net/test/mallhdr.env',
                scene,
            );
            scene.environmentTexture.level = 0.1;

            return scene;
        },
        CreateEngine() {
            var engine = new BABYLON.Engine(this.$refs.rendercanvas, true, {
                preserveDrawingBuffer: true,
                stencil: true,
            });
            this.$options.engine = engine;
            var scene = this.CreateScene();

            //engine.fovMode=FOVMODE_HORIZONTAL_FIXED;

            engine.runRenderLoop(() => {
                if (scene) {
                    engine.resize();
                    scene.render();
                }
            });

            // Resize
            window.addEventListener('resize', function() {
                engine.resize();
            });

            this.AddNewBabylonInstance({
                id: this.id,
                engine: engine,
                scene: scene,
                canvas: this.$refs.rendercanvas,
            });

            this.$emit('init');
        },
    },
    watch: {},
};
</script>

<style scoped>
#renderCanvas {
    width: 100%;
    height: 100%;
    touch-action: none;
}
</style>
