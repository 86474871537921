<template>
    <!-- <v-dialog v-model="isVisible" width="500" persistent overlay-opacity="0.8">
        <v-card>
            <v-toolbar color="grey darken-4" dark flat>
                <v-toolbar-title class="title">
                    {{ title }}
                </v-toolbar-title>
            </v-toolbar>
            <v-card-text class="mt-4">
                <p class="mb-0 body-1">{{ body }}</p>
            </v-card-text>
        </v-card>
    </v-dialog> -->
    <v-overlay v-model="isVisible" opacity="0.8" />
</template>

<script>
export default {
    name: 'WelcomeDialog',
    components: {},
    props: {
        isVisible: Boolean,
        title: String,
        body: String,
    },
    computed: {},

    mounted() {},
    data: () => ({}),
    methods: {},
    watch: {},
};
</script>
