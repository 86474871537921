<template>
  <div>
    <h1>Signing in...</h1>
  </div>
</template>

<script>
import { SigninSilentCallback } from "../../js/authentication/authentication";

export default {
  mounted() {
    SigninSilentCallback()
  }
};
</script>