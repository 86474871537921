<template>
    <v-menu right offset-y>
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" small text class="pa-6">
                <v-icon left>
                    mdi-application-cog
                </v-icon>
                Admin
            </v-btn>
        </template>

        <v-list>
            <v-list-item v-for="(item, i) in items" :key="i" :to="item.path">
                <v-list-item-content>
                    <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
export default {
    name: 'AdminMenu',
    components: {},
    props: {},
    computed: {},

    mounted() {},
    data: () => ({
        items: [
            { text: 'Environments', path: '/admin/environments' },
            { text: 'Shoppers', path: '/admin/shoppers' },
        ],
    }),
    methods: {},
    watch: {},
};
</script>
