import { GetUser } from '../js/authentication/authentication';

export default {
    namespaced: true,

    state: {
        loggedIn: false,
        userName: '',
        accessToken: '',
        subid: null,
        claims: {
            userHasAccessToApplication: false,
            canEditAssets: true,
            canViewNPD: false,
            canManageTaxonomies: false,
            isUserSuper: false,
        },
    },

    actions: {
        test({ commit, state, rootState }) {},
        async setLoginStateAsDev(context) {
            context.commit('logIn');
            context.commit('setUserName', 'TestUser');
        },
        async updateLoggedInStatus(context) {
            var user = await GetUser();

            if (user) {
                context.commit('logIn');
                context.commit('setUserName', user.profile.username);
                context.commit('setAccessToken', user.access_token);
                context.commit('setsubid', user.profile.username);
                return;
            }

            context.commit('logOut');
            context.commit('setUserName', '');
        },
    },
    mutations: {
        logIn(state) {
            state.loggedIn = true;
        },
        logOut(state) {
            state.loggedIn = false;
        },
        setsubid(state, subid) {
            state.subid = subid;
        },
        setUserName(state, userName) {
            state.userName = userName;
        },
        setAccessToken(state, accessToken) {
            state.accessToken = accessToken;
        },
        userHasAccessToApplication(state, hasAccess) {
            state.claims.userHasAccessToApplication = hasAccess;
        },
        canEditAssets(state, hasAccess) {
            state.claims.canEditAssets = hasAccess;
        },
        canViewNPD(state, hasAccess) {
            state.claims.canViewNPD = hasAccess;
        },
        canManageTaxonomies(state, hasAccess) {
            state.claims.canManageTaxonomies = hasAccess;
        },
        SetAsSuperUser(state, isSuperUser) {
            state.claims.isUserSuper = isSuperUser;
        },
    },
    getters: {
        IsSuperUser: state => {
            return state.claims.isUserSuper;
        },
        GetAuthHeaderObj: state => {
            return { Authorization: 'Bearer ' + state.accessToken };
        },
    },
};
