<style>
.tag {
    font-weight: normal;
    font-size: 12px;
}
.title {
    flex: 1;
}
</style>
<template>
    <div>
        <v-card width="180" dark>
            <v-toolbar color="grey darken-4 pa-0" dark flat height="40">
                <v-toolbar-title class="title">
                    <div class="body-1 font-weight-bold">
                        {{ title }}
                    </div>
                </v-toolbar-title>
                <v-btn icon @click="$emit('clear-shopper')" color="white" small>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-img height="80" :src="imageSrc"> </v-img>
            <v-card-title class="pa-1">
                <v-chip
                    v-for="tag in tags"
                    :ripple="false"
                    :key="tag"
                    label
                    dark
                    small
                    color="grey darken-4"
                >
                    <v-icon left x-small>
                        mdi-label
                    </v-icon>
                    {{ tag }}
                </v-chip>
            </v-card-title>
        </v-card>
    </div>
</template>

<script>
export default {
    name: 'ShopperMiniProfile',
    components: {},
    props: {
        title: String,
        imageSrc: String,
        tags: Array,
    },
    computed: {},

    mounted() {},
    data: () => ({}),
    methods: {},
    watch: {},
};
</script>
