<template>
    <v-app :style="{ background: $vuetify.theme.themes[theme].background }">
        <router-view></router-view>
    </v-app>
</template>

<script>
// import axios from 'axios'
// import config from '../config.json'
// import LoadingScreen from './components/LoadingScreen.vue';
// import { mapMutations } from 'vuex'
// import { mapState } from 'vuex'

export default {
    name: 'App',

    components: {
        // LoadingScreen,
    },
    computed: {
        theme() {
            return this.$vuetify.theme.dark ? 'dark' : 'light';
        },
    },

    data: () => ({
        //gotPackageData : false
    }),
    mounted: function() {
        // console.log(this.$route.params);
        // if(this.$route.params.packageid != null)
        // {
        //   var url = config.platformEndpoints.coreblobstorage + "packagesv2/" +   this.$route.params.packageid + ".json";
        //   console.log("Getting package at url: " + url);
        //   axios.get(url)
        //   .then((response) => {
        //     this.SetPackageData({id : this.$route.params.packageid, data : response.data});
        //     this.gotPackageData = true;
        //     this.SetStageDataIfRequired();
        //   })
        // }
    },
    methods: {
        // ...mapMutations("sessionState", [
        //     "SetPackageData",
        //     "SetStageData"
        // ]),
        // SetStageDataIfRequired()
        // {
        //   console.log("Setting stage data if required");
        //   if(this.$route.params.stageid != null)
        //   {
        //     var stage = this.package.stages.filter(item => item.id == this.$route.params.stageid);
        //     if(stage.length > 0)
        //     {
        //       this.SetStageData(stage[0]);
        //     }
        //   }
        // }
    },
    watch: {
        // $route (to, from){
        // }
    },
};
</script>
