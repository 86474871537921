<template>
    <v-container
        style="position:absolute;pointer-events:none"
        fill-height
        fluid
    >
        <v-row
            no-gutters
            align="start"
            style="height: 100%;padding-bottom:20px"
        >
            <v-spacer />

            <v-btn
                dark
                style="pointer-events:auto"
                class="ml-2"
                @click="trackerVisible = !trackerVisible"
            >
                {{ trackerVisible ? 'Hide Event Log' : 'Show Event Log' }}
            </v-btn>

            <v-card width="600px" dark class="ml-4" v-if="trackerVisible">
                <div class="title pa-2">
                    Event Log
                </div>
                <v-data-table
                    style="pointer-events:auto"
                    :headers="headers"
                    :items="events"
                    :items-per-page="8"
                    class="elevation-1"
                    :hide-default-footer="true"
                    :page="logPage"
                    height="480px"
                ></v-data-table>
                <v-pagination
                    v-if="numOfPages > 1"
                    style="pointer-events:auto"
                    v-model="logPage"
                    :length="numOfPages"
                    class="pb-4"
                ></v-pagination>
            </v-card>
        </v-row>
    </v-container>
</template>

<script>
import { mapState } from 'vuex';
import { mapMutations } from 'vuex';
export default {
    name: 'EventTracker',
    components: {},
    props: {},
    computed: {
        ...mapState('trackedState', ['events']),

        numOfPages() {
            return 1 + Math.floor(this.events.length / 8);
        },
    },

    mounted: () => {},
    data: () => ({
        logPage: 1,
        headers: [
            { text: 'Type', value: 'type' },
            { text: 'Key', value: 'key' },
            { text: 'Date', value: 'time' },
        ],
        initalized: false,
        trackerVisible: false,
    }),
    methods: {
        ...mapMutations('trackedState', ['AddnewEvent']),
    },
    watch: {},
};
</script>
