var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"800","persistent":"","overlay-opacity":"0.8"},model:{value:(_vm.isVisible),callback:function ($$v) {_vm.isVisible=$$v},expression:"isVisible"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"grey darken-4","dark":"","flat":""}},[_c('v-toolbar-title',{staticClass:"title"},[_c('v-text-field',{staticClass:"title-field",attrs:{"label":"Add your title here...","hide-details":"auto","single-line":"","background-color":"transparent"},model:{value:(_vm.marker.content.title),callback:function ($$v) {_vm.$set(_vm.marker.content, "title", $$v)},expression:"marker.content.title"}})],1),_c('v-btn',{attrs:{"icon":"","color":"white"},on:{"click":_vm.onClose}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-container',{attrs:{"pa-0":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"md":"5"}},[_c('div',{staticClass:"image-grid no-gutters"},[_c('draggable',{model:{value:(_vm.marker.content.resources),callback:function ($$v) {_vm.$set(_vm.marker.content, "resources", $$v)},expression:"marker.content.resources"}},[_c('transition-group',{staticClass:"row no-gutters"},[_vm._l((_vm.marker.content
                                        .resources),function(item,index){return [_c('v-col',{key:index,attrs:{"cols":"12","sm":"4"}},[_c('div',{staticClass:"square-block"},[_c('v-img',{staticClass:"square-block__inner",attrs:{"height":"100%","width":"100%","cover":"","src":item.url},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5","size":20,"width":3}})],1)]},proxy:true}],null,true)}),(
                                                    !item.url.includes(
                                                        'blob:'
                                                    )
                                                )?_c('v-icon',{staticClass:"image-cloud",attrs:{"small":"","color":"primary"}},[_vm._v("mdi-cloud-check")]):_vm._e()],1)])]})],2)],1)],1)]),_c('v-col',{attrs:{"md":"7"}},[_c('v-layout',{attrs:{"pt-3":"","px-3":"","column":"","align-content-end":""}},[_c('vue-editor',{staticClass:"pb-3",attrs:{"id":"editor","editor-toolbar":_vm.customToolbar},model:{value:(_vm.marker.content.body),callback:function ($$v) {_vm.$set(_vm.marker.content, "body", $$v)},expression:"marker.content.body"}}),_c('v-select',{staticClass:"mb-5",attrs:{"items":_vm.shoppers,"chips":"","clearable":"","label":"Display with the following shopper(s)","item-text":"title","item-value":"id","return-object":false,"hide-details":"auto","multiple":"","offset-lg10":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                                                var item = ref.item;
return [_c('v-avatar',{staticClass:"mr-2",attrs:{"color":"primary","size":"28"}},[(item.resources[0])?_c('v-img',{attrs:{"src":item.resources[0].url,"alt":item.title,"cover":""}}):_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-account-circle ")])],1),_c('span',{staticClass:"body-2"},[_vm._v(_vm._s(item.title))])]}},{key:"selection",fn:function(ref){
                                var attrs = ref.attrs;
                                var item = ref.item;
                                var select = ref.select;
                                var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"pill":"","input-value":selected,"close":"","small":"","color":"primary"},on:{"click":select,"click:close":function($event){return _vm.removeTags(item)}}},'v-chip',attrs,false),[_c('strong',[_vm._v(_vm._s(item.title))])])]}}]),model:{value:(_vm.marker.shoppers),callback:function ($$v) {_vm.$set(_vm.marker, "shoppers", $$v)},expression:"marker.shoppers"}})],1)],1)],1),_c('v-card-actions',{staticClass:"card-actions"},[_c('label',{staticClass:"v-btn v-btn--outlined v-btn--text theme--light v-size--default upload-btn",attrs:{"for":"upload-file"}},[_c('input',{ref:"file",attrs:{"id":"upload-file","type":"file","multiple":"multiple","accept":".jpg, .jpeg, .png","hidden":""},on:{"change":function($event){return _vm.setResources($event.target.files)}}}),_c('span',{staticClass:"v-btn__content"},[_vm._v("Add images")]),_c('v-icon',{staticClass:"ml-2",attrs:{"aria-label":"Add image","role":"img","aria-hidden":"false","medium":""}},[_vm._v(" mdi-image-plus ")])],1),_c('div',[_c('v-btn',{attrs:{"text":"","outlined":""},on:{"click":_vm.onDelete}},[_vm._v(" Delete ")]),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","disabled":_vm.isLoading || _vm.saved},on:{"click":_vm.onSave}},[(_vm.isLoading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":20,"width":3}}):_c('span',[_vm._v(" "+_vm._s(_vm.saved ? 'Saved' : 'Save')+" ")])],1)],1)])],1)],1),_c('v-snackbar',{attrs:{"timeout":-1,"value":!!_vm.isLoadingMessage,"color":"primary","rounded":"pill"}},[_c('p',{staticClass:"text-center mb-0"},[_vm._v(_vm._s(_vm.isLoadingMessage))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }