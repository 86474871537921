<style scoped>
.root {
    max-width: 900px;
    margin-top: 100px;
}
</style>

<template>
    <v-main>
        <navigation-header>
            <template v-slot:navigationLeft>
                <v-btn small text class="pa-6" to="/">
                    <v-icon left>
                        mdi-arrow-left
                    </v-icon>
                    Dashboard
                </v-btn>
            </template>
        </navigation-header>

        <v-container class="root">
            <v-layout>
                <h1 class="title mb-5">Shoppers</h1>
                <v-btn class="ml-5" color="primary" @click="onAddNewPress"
                    >Add New</v-btn
                >
            </v-layout>

            <v-card>
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th width="50"></th>
                                <th class="text-left">
                                    <span class="pl-4">
                                        Name
                                    </span>
                                </th>
                                <th class="text-left">
                                    <span class="pl-3">
                                        Tags
                                    </span>
                                </th>
                                <th class="text-right">
                                    <span class="pl-4">
                                        Environment
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="item in shoppers"
                                :key="item.title"
                                @click="onRowClick(item.id)"
                            >
                                <td>
                                    <v-avatar color="primary" size="32">
                                        <v-img
                                            v-if="item.resources[0]"
                                            :src="item.resources[0].url"
                                            :alt="item.title"
                                            cover
                                        />
                                        <v-icon v-else dark>
                                            mdi-account-circle
                                        </v-icon>
                                    </v-avatar>
                                </td>
                                <td>
                                    <span class="body-2">{{ item.title }}</span>
                                </td>
                                <td>
                                    <v-chip
                                        v-for="tag in item.tags"
                                        class="ma-2"
                                        :ripple="false"
                                        :key="tag"
                                        small
                                        color="grey lighten-2"
                                    >
                                        {{ tag }}
                                    </v-chip>
                                </td>
                                <td class="text-right">
                                    {{ item.environmentid }}
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card>
        </v-container>
    </v-main>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import NavigationHeader from '../components/Global/NavigationHeader';
import { v4 as uuidv4 } from 'uuid';
import router from '../router';

export default {
    name: 'Shoppers',
    components: { NavigationHeader },
    props: {},
    computed: {
        ...mapState('shoppers', ['shoppers']),
    },

    mounted() {
        this.fetchShoppers();
    },
    data: () => ({}),
    methods: {
        ...mapActions('shoppers', ['fetchShoppers']),

        onAddNewPress() {
            const shopperId = uuidv4();

            router.push({
                name: 'AddShopper',
                params: { shopperId },
                query: { type: 'new' },
            });
        },
        onRowClick(shopperId) {
            this.$router.push({ name: 'AddShopper', params: { shopperId } });
        },
    },
    watch: {},
};
</script>
