import Vue from 'vue';
import VueRouter from 'vue-router';
import LoadEnvironment from '../components/Stages/LoadEnvironment.vue';

import Dashboard from '../views/Dashboard.vue';
import ViewEnvironments from '../views/ViewEnvironments.vue';
import AddEnvironment from '../views/AddEnvironment.vue';
import ViewShoppers from '../views/ViewShoppers.vue';
import AddShopper from '../views/AddShopper.vue';

import SignInCallback from '../components/Auth/SignInCallback.vue';
import SilentRenewCallback from '../components/Auth/SilentRenewCallback.vue';
import NoAccess from '../components/Auth/NoAccess.vue';
// import StagePresenter from '../components/Stages/StagePresenter.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/project/:projectId',
        name: 'LoadEnvironment',
        display: 'LoadEnvironment',
        component: LoadEnvironment,
        props: true,
    },
    {
        // TODO: Lazy load this
        path: '/admin/shoppers/:shopperId',
        name: 'AddShopper',
        display: 'Add Shopper',
        component: AddShopper,
        props: true,
    },
    {
        // TODO: Lazy load this
        path: '/admin/shoppers/',
        name: 'ViewShoppers',
        display: 'View Shoppers',
        component: ViewShoppers,
        props: true,
    },
    {
        // TODO: Lazy load this
        path: '/admin/environments/:environmentId',
        name: 'AddEnvironment',
        display: 'Add Environment',
        component: AddEnvironment,
        props: true,
    },
    {
        // TODO: Lazy load this
        path: '/admin/environments/',
        name: 'ViewEnvironments',
        display: 'View Environments',
        component: ViewEnvironments,
        props: true,
    },
    {
        // TODO: Lazy load this
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
    },
    {
        path: '/callback',
        name: 'callback',
        component: SignInCallback,
    },
    {
        path: '/silentRenewCallback',
        name: 'silentRenewCallback',
        component: SilentRenewCallback,
    },
    {
        path: '/noAccess',
        name: 'noAccess',
        component: NoAccess,
    },

    /*
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/About.vue')
  }
  */
];

const router = new VueRouter({
    mode: 'hash',
    //base: process.env.BASE_URL,
    base: '/',
    routes,
});

export default router;
