import router from '../router/index';
import orderBy from 'lodash/orderBy';
import unionBy from 'lodash/unionBy';
import union from 'lodash/union';
import axios from 'axios';
import CONFIG from '@/../config.json';
import {
    GetAuthorisationHeaderGroup,
    GetBearerToken,
} from './assetxphelpers.js';
// import { v4 as uuidv4 } from 'uuid';

const api = axios.create({
    baseURL: CONFIG.platformEndpoints.assetxpapi,
});

export const ProjectState = {
    namespaced: true,
    state: {
        projects: [],
    },

    mutations: {
        setProjects(state, payload) {
            state.projects = payload;
        },
        setProject(state, payload) {
            // console.log('setProject');

            state.projects = unionBy([payload], state.projects, 'id');
        },
    },
    actions: {
        async fetchProjectMarkers({ commit }) {
            try {
                const { data } = await api.get(
                    `storeviewer/projects/getmyprojects`,
                    GetAuthorisationHeaderGroup(),
                );
                commit('setProjects', data);
            } catch (e) {
                // empty
            }
        },
        async fetchProject({ commit }, projectId) {
            try {
                const { data } = await api.get(
                    `storeviewer/projects/get/${projectId}`,
                    GetAuthorisationHeaderGroup(),
                );
                commit('setProject', data);
            } catch (e) {
                //
            }
        },
        async deleteProject({ commit }, projectId) {
            try {
                await api.get(
                    `storeviewer/projects/delete/${projectId}`,
                    GetAuthorisationHeaderGroup(),
                );

                return true;
            } catch (e) {
                return false;
            }
        },
        async upsertProject({ commit }, project) {
            try {
                const { data } = await api.post(
                    `storeviewer/projects/upsert`,
                    project,
                    GetAuthorisationHeaderGroup(),
                );

                commit('setProject', data);

                return true;
            } catch (e) {
                return false;
            }
        },

        async upsertMarker({ getters, dispatch }, marker) {
            // Move this?
            const { projectId } = router.history.current.params;

            const project = getters.getProject(projectId);

            const updatedProject = {
                ...project,
                markers: unionBy([marker], project.markers, 'id'),
            };

            const response = await dispatch('upsertProject', updatedProject);

            return response;
        },

        async upsertShopperMarkers({ getters, dispatch }, markers) {
            const { projectId } = router.history.current.params;

            const project = getters.getProject(projectId);

            const updatedProject = {
                ...project,
                markers: unionBy(markers, project.markers, 'id'),
            };

            const response = await dispatch('upsertProject', updatedProject);

            return response;
        },

        async deleteMarker({ getters, dispatch }, markerId) {
            const { projectId } = router.history.current.params;

            const project = getters.getProject(projectId);

            const updatedProject = {
                ...project,
                markers: project.markers.filter(o => o.id !== markerId),
            };

            const response = await dispatch('upsertProject', updatedProject);

            return response;
        },
    },
    getters: {
        getProjects: state => {
            return orderBy(state.projects, ['lastUpdated'], ['desc']);
        },
        getProject: state => id => {
            const project = state.projects.find(obj => obj.id === id);

            if (!project) return null;

            return {
                ...project,
                markers: orderBy(project.markers, ['order'], ['asc']),
            };
        },
        getEnvironment: state => id => {
            return state.projects.find(obj => obj.id === id);
        },
        getProjectMarkers: state => (projectId, { waypoint, shopperId }) => {
            const project = state.projects.find(obj => obj.id === projectId);

            if (!project) return [];

            return project.markers.filter(
                obj =>
                    obj.waypoint === waypoint &&
                    obj.shoppers.includes(shopperId),
            );
        },
        getProjectShopperMarkers: state => ({ projectId, shopperId }) => {
            const project = state.projects.find(obj => obj.id === projectId);

            if (!project) return [];

            return project.markers.filter(obj =>
                obj.shoppers.includes(shopperId),
            );
        },
        getProjectMarker: (state, getters) => (projectId, id) => {
            const project = state.projects.find(obj => obj.id === projectId);

            if (!project) return null;

            return project.markers.find(obj => obj.id === id);
        },
        getShoppersByProject: state => projectId => {
            const project = state.projects.find(obj => obj.id === projectId);

            if (!project) return [];

            const shoppers = project.markers.map(obj => obj.shoppers);

            return union(...shoppers);
        },
    },
};
