export const UserState = {
    namespaced: true,
    state: {
        isWelcomeVisible: true,
    },

    mutations: {
        hideWelcome(state) {
            state.isWelcomeVisible = false;
        },
    },
    actions: {},
    getters: {},
};
