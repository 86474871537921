<style scoped>
.close-button {
    position: absolute;
    top: 5px;
    right: 5px;
}
.title {
    flex: 1;
}
.shopper-btn {
    position: absolute;
    left: 20px;
    bottom: 20px;
    z-index: 1;
}
.shopper-carousel {
    padding-bottom: 80px;
}
.image-container {
    background-size: cover;
    background-position: center center;
}
</style>

<template>
    <fragment>
        <v-dialog
            ref="test"
            v-model="isVisible"
            persistent
            overlay-opacity="0.95"
            content-class="elevation-0"
            width="100%"
        >
            <!-- delimiter-icon="mdi-account-circle" -->
            <v-carousel
                hide-delimiter-background
                height="auto"
                dark
                class="shopper-carousel"
            >
                <v-carousel-item v-for="(item, i) in shopperArr" :key="i">
                    <v-layout justify-center>
                        <v-card class="mx-auto my-12" max-width="500" light>
                            <v-toolbar color="grey darken-4" dark flat>
                                <v-toolbar-title class="title">
                                    {{ item.title }}
                                </v-toolbar-title>
                                <v-btn icon @click="onClose" color="white">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </v-toolbar>
                            <v-img
                                v-if="item.resources[0]"
                                height="180"
                                :src="item.resources[0].url"
                            >
                                <v-layout fill-height align-end class="pa-4">
                                    <v-chip
                                        v-for="tag in item.tags"
                                        :ripple="false"
                                        :key="tag"
                                        label
                                        dark
                                        color="grey darken-4"
                                    >
                                        <v-icon left small>
                                            mdi-label
                                        </v-icon>
                                        {{ tag }}
                                    </v-chip>
                                </v-layout>
                            </v-img>

                            <v-card-text class="body-1" v-html="item.body" />

                            <v-card-actions>
                                <v-btn
                                    color="primary"
                                    width="100%"
                                    :disabled="item.id === shopper.id"
                                    @click="onSelect(item)"
                                >
                                    {{
                                        item.id === shopper.id
                                            ? 'Selected shopper'
                                            : 'Select shopper'
                                    }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-layout>
                </v-carousel-item>
            </v-carousel>
        </v-dialog>

        <v-btn large text class="pa-6" @click="onOpenPress" dark>
            <v-icon left>
                {{
                    shopper.id
                        ? 'mdi-account-multiple-check'
                        : 'mdi-account-multiple'
                }}
            </v-icon>
            {{ shopper.id ? 'Change shopper' : 'Select a shopper' }}
        </v-btn>
    </fragment>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { Fragment } from 'vue-fragment';

const defaultShopper = {
    id: '',
    title: '',
    url: '',
};

export default {
    name: 'ShopperSelector',
    components: {
        Fragment,
    },

    props: {
        id: {
            type: [String, null],
            default: '',
        },
        selectedId: String,
    },

    computed: {
        ...mapState('shoppers', ['shoppers']),
        ...mapGetters('shoppers', ['getProjectShoppers']),

        shopperArr() {
            const { query, params } = this.$router.history.current;

            if (query?.presentation === 'on') {
                return this.getProjectShoppers(params.projectId);
            }

            return this.shoppers;
        },
    },

    mounted() {
        this.fetchShoppers();
    },

    data: () => ({
        isVisible: false,
        shopper: defaultShopper,
    }),

    methods: {
        onClose() {
            this.isVisible = false;
            //this.$emit('closed');
        },

        ...mapActions('shoppers', ['fetchShoppers']),

        onOpenPress() {
            this.isVisible = true;
        },

        onSelect(shopper) {
            this.isVisible = false;
            this.shopper = shopper;
            this.$emit('selected-shopper', shopper);
        },

        onClear() {
            this.shopper = defaultShopper;
            this.$emit('selected-shopper', defaultShopper);
        },
    },
    watch: {},
};
</script>

<style>
.shopper-dialog {
    box-shadow: none;
}
</style>
