<style>
/* .presentationToolbar {
    position: absolute;
    bottom: 30px;
    right: 30px;
    z-index: 1;
} */
.presentationToolbar__sheet {
    overflow: hidden;
}
</style>

<template>
    <fragment v-if="totalMarkers > 0">
        <v-btn
            large
            dark
            text
            class="pa-6"
            @click="goToMarker('prev')"
            :disabled="index === 0"
        >
            <v-icon>mdi-arrow-left</v-icon>
            <!-- Prev -->
        </v-btn>

        <v-btn
            v-if="editMode"
            large
            dark
            text
            class="pa-6"
            @click="handleOrderToggle"
        >
            <v-icon>mdi-sort</v-icon>
            <!-- Order -->
        </v-btn>

        <v-btn v-else large dark text class="pa-6" @click="handleReset">
            <v-icon>mdi-home</v-icon>
            <!-- Order -->
        </v-btn>

        <v-btn
            large
            dark
            text
            class="pa-6"
            @click="goToMarker('next')"
            :disabled="index === totalMarkers - 1"
        >
            <!-- Next -->
            <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
        <transition-order
            :isVisible="isOrderVisible"
            :selectedShopperId="selectedShopperId"
            @closed="handleOrderToggle"
        />
    </fragment>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { Fragment } from 'vue-fragment';

import TransitionOrder from './TransitionOrder.vue';

export default {
    name: 'StoreViewerNavigation',
    components: { TransitionOrder, Fragment },
    props: {
        editMode: Boolean,
        selectedShopperId: String,
    },
    computed: {
        ...mapGetters('sessionState', ['GetBabPnl']),
        ...mapState('sessionState', ['activeWaypointid']),
        ...mapGetters('projects', ['getProjectShopperMarkers']),

        shopperMarkers() {
            const { projectId } = this.$route.params;

            return this.getProjectShopperMarkers({
                projectId,
                shopperId: this.selectedShopperId,
            });
        },
    },
    mounted() {},
    data: () => ({
        toolbarToggle: 0,
        markers: [],
        totalMarkers: 0,
        index: -1,
        isOrderVisible: false,
    }),
    methods: {
        ...mapMutations('sessionState', ['SetJumpToWaypoint']),

        getMarker(dir) {
            // TODO: Disable buttons no where to go!

            if (this.markers.length < 1) return null;

            if (dir === 'reset') {
                this.index = 0;
            } else if (dir === 'next' && this.index + 1 < this.totalMarkers) {
                console.log('next');
                this.index = this.index + 1;
            } else if (dir === 'prev' && this.index > 0) {
                console.log('prev');
                this.index = this.index - 1;
            } else {
                return null;
            }

            const { waypoint, points } = this.markers[this.index];

            if (waypoint !== this.activeWaypointid) {
                this.SetJumpToWaypoint(waypoint);
            }

            return points;
        },

        goToMarker(dir) {
            const frameRate = 10;

            const { scene } = this.GetBabPnl('MainPanel');

            var camera = scene.activeCamera;

            var animationcamera = new BABYLON.Animation(
                'pathAnim',
                'target',
                frameRate,
                BABYLON.Animation.ANIMATIONTYPE_VECTOR3,
                BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT,
            );

            const currentCameraTarget = camera.target.clone();

            const points = this.getMarker(dir);

            if (points) {
                const { x, y, z } = points;
                const newPos = new BABYLON.Vector3(x, y, z);

                const distance = BABYLON.Vector3.Distance(
                    currentCameraTarget,
                    newPos,
                );

                var keys = [];

                keys.push({
                    frame: 0,
                    value: currentCameraTarget,
                });

                keys.push({
                    frame: distance / frameRate,
                    value: newPos,
                });

                animationcamera.setKeys(keys);
                camera.animations = [];
                camera.animations.push(animationcamera);

                scene.beginAnimation(camera, 0, distance / frameRate, false);
            }
        },
        handleReset() {
            this.index = 0;
            this.$emit('reset');
        },
        handleOrderToggle() {
            this.isOrderVisible = !this.isOrderVisible;
        },
    },
    watch: {
        shopperMarkers: {
            immediate: true,
            handler(n) {
                //console.log(n);

                this.markers = n;
                this.totalMarkers = n.length;
                this.index = -1;
            },
        },
    },
};
</script>
