<style scoped>
.list {
    max-height: 500px;
    overflow: auto;
}
.item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    background-color: white;
}
.actions {
    /* justify-content: flex-end; */
}
.close-button {
    position: absolute;
    top: 5px;
    right: 5px;
}
</style>

<template>
    <v-dialog v-model="isVisible" width="500" persistent overlay-opacity="0.8">
        <v-card>
            <v-toolbar color="grey darken-4" dark flat>
                <v-toolbar-title>Reorder areas of interest</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="onClose" color="white">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-list class="list">
                <draggable v-model="markers">
                    <transition-group>
                        <template v-for="(item, index) in markers">
                            <v-list-item
                                :key="item.id"
                                class="item"
                                v-if="item.content"
                            >
                                <v-list-item-icon>
                                    <v-icon>
                                        mdi-drag-horizontal-variant
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title
                                        v-text="item.content.title"
                                    ></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </transition-group>
                </draggable>
            </v-list>
            <v-card-actions class="justify-end">
                <v-btn color="primary" @click="onSave" :disabled="saved">
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import draggable from 'vuedraggable';
import { mapGetters, mapActions } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

export default {
    name: 'TransitionOrder',
    components: {
        draggable,
    },
    props: {
        isVisible: Boolean,
        selectedShopperId: String,
    },
    computed: {
        ...mapGetters('projects', ['getProjectShopperMarkers']),

        shopperMarkers() {
            const { projectId } = this.$route.params;

            return this.getProjectShopperMarkers({
                projectId,
                shopperId: this.selectedShopperId,
            });
        },
    },

    // mounted() {
    //     this.updateMarkers();
    // },

    data: () => ({
        markers: [],
        saved: true,
    }),
    methods: {
        ...mapActions('projects', ['upsertShopperMarkers']),

        // updateMarkers() {
        //     this.markers = this.getProjectMarkers;
        // },

        onSave() {
            const markers = this.markers.map((obj, index) => ({
                ...obj,
                order: index,
            }));

            this.upsertShopperMarkers(markers);

            this.$emit('closed');
        },
        onClose() {
            this.$emit('closed');
        },
    },
    watch: {
        markers: {
            immediate: false,
            handler: function(n) {
                this.saved = isEqual(this.shopperMarkers, n);
            },
            deep: true,
        },
        shopperMarkers: {
            immediate: true,
            handler(n) {
                this.markers = cloneDeep(n);
            },
        },
    },
};
</script>
