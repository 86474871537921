import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import * as sessionState from './SessionState';
import * as trackedState from './TrackedState';
import * as projectState from './ProjectState';
import * as templateState from './TemplateState';
import * as environmentState from './EnvironmentState';
import * as shopperState from './ShopperState';
import * as userState from './UserState';
import Authentication from './authentication';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
    key: 'storeviewer',
    storage: window.localStorage,
    supportCircular: true,
    reducer: state => ({
        // projects: state.projects,
        // markers: state.markers,
        // templates: state.templates,
        // templateMarkers: state.templateMarkers,
        user: state.user,
    }),
});

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        authentication: Authentication,
        sessionState: sessionState.SessionState,
        trackedState: trackedState.TrackedState,
        projects: projectState.ProjectState,
        templates: templateState.TemplateState,
        environments: environmentState.EnvironmentState,
        shoppers: shopperState.ShopperState,
        user: userState.UserState,
    },
    plugins: [vuexLocal.plugin],
});
