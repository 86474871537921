<style scoped>
.close-button {
    position: absolute;
    top: 5px;
    right: 5px;
}
.title {
    flex: 1;
}
</style>

<template>
    <v-dialog v-model="isVisible" width="800" persistent overlay-opacity="0.8">
        <v-card>
            <v-toolbar color="grey darken-4" dark flat>
                <v-toolbar-title class="title" v-if="marker.content">
                    {{ marker.content.title }}
                </v-toolbar-title>
                <v-btn icon @click="onClose" color="white">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-container pa-0 v-if="marker.content">
                <v-row no-gutters>
                    <v-col md="5" v-if="marker.content.resources.length > 0">
                        <!-- TODO: Add loader for images -->
                        <v-carousel
                            height="400"
                            show-arrows-on-hover
                            hide-delimiter-background
                            cycle
                        >
                            <v-carousel-item
                                v-for="(item, i) in this.marker.content
                                    .resources"
                                :key="i"
                                :src="item.url"
                            ></v-carousel-item>
                        </v-carousel>
                    </v-col>
                    <v-col md="7">
                        <v-layout pa-3 column align-content-end fill-height>
                            <div v-html="marker.content.body" />
                        </v-layout>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'InsightEditor',
    components: {},

    props: {
        id: {
            type: [String, null],
            default: '',
        },
    },

    computed: {
        ...mapGetters('projects', ['getProjectMarker']),

        marker() {
            const { projectId } = this.$route.params;
            return this.getProjectMarker(projectId, this.id);
        },
    },

    data: () => ({
        isVisible: true,
    }),

    methods: {
        onClose() {
            this.$emit('closed');
        },
    },
    watch: {},
};
</script>
