<style scoped>
.root {
    max-width: 900px;
    margin-top: 100px;
}
</style>

<template>
    <v-main class="main">
        <navigation-header>
            <template v-slot:navigationLeft>
                <v-btn small text class="pa-6" to="/">
                    <v-icon left>
                        mdi-arrow-left
                    </v-icon>
                    Dashboard
                </v-btn>
            </template>
        </navigation-header>

        <v-container class="root" xs>
            <h1 class="title mb-5">Add/Edit a environment</h1>
            <v-form>
                <v-card>
                    <v-row no-gutters>
                        <v-col cols="12" sm="12">
                            <v-container class="px-5">
                                <v-text-field
                                    v-model="environment.name"
                                    label="Name"
                                    required
                                    :error-messages="nameErrors"
                                    @input="$v.environment.name.$touch()"
                                    @blur="$v.environment.name.$touch()"
                                    class="mb-5"
                                    hide-details="auto"
                                ></v-text-field>

                                <div>
                                    <div v-if="environment.resources[0]">
                                        <v-img
                                            height="150px"
                                            width="150px"
                                            cover
                                            :src="environment.resources[0].url"
                                            class="align-end"
                                        >
                                            <template v-slot:placeholder>
                                                <v-row
                                                    class="fill-height ma-0"
                                                    align="center"
                                                    justify="center"
                                                >
                                                    <v-progress-circular
                                                        indeterminate
                                                        color="primary"
                                                        :size="20"
                                                        :width="3"
                                                    ></v-progress-circular>
                                                </v-row>
                                            </template>
                                            <v-layout
                                                justify-center
                                                class="ma-3"
                                            >
                                                <v-btn
                                                    elevation="2"
                                                    fab
                                                    small
                                                    dark
                                                    color="error"
                                                    @click="removeImage()"
                                                >
                                                    <v-icon
                                                        >mdi-delete-outline</v-icon
                                                    >
                                                </v-btn>
                                            </v-layout>
                                        </v-img>
                                    </div>
                                    <label
                                        v-else
                                        for="upload-file"
                                        class="v-btn v-btn--outlined v-btn--text theme--light v-size--default"
                                    >
                                        <input
                                            id="upload-file"
                                            type="file"
                                            ref="file"
                                            accept=".jpg, .jpeg, .png"
                                            @change="
                                                addImage($event.target.files[0])
                                            "
                                            hidden
                                        />
                                        <span class="v-btn__content"
                                            >Add Image</span
                                        >
                                        <v-icon
                                            aria-label="Add image"
                                            role="img"
                                            aria-hidden="false"
                                            medium
                                            class="ml-2"
                                        >
                                            mdi-account-box
                                        </v-icon>
                                    </label>
                                </div>
                            </v-container>
                        </v-col>
                    </v-row>

                    <v-divider></v-divider>
                    <v-card-actions class="justify-end pa-4">
                        <v-btn text @click="onDelete" outlined>
                            Delete
                        </v-btn>
                        <v-btn
                            color="primary"
                            :disabled="isLoading || saved"
                            @click="onSave"
                            class="ml-2"
                        >
                            <v-progress-circular
                                indeterminate
                                color="primary"
                                :size="20"
                                :width="3"
                                v-if="isLoading"
                            ></v-progress-circular>
                            <span v-else>
                                Save
                            </span>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-container>
        <v-snackbar
            :timeout="-1"
            :value="!!isLoadingMessage"
            color="primary"
            rounded="pill"
        >
            <p class="text-center mb-0">{{ isLoadingMessage }}</p>
        </v-snackbar>
    </v-main>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import shortid from 'shortid';
import axios from 'axios';
import NavigationHeader from '../components/Global/NavigationHeader';
import { required } from 'vuelidate/lib/validators';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

import { v4 as uuidv4, validate as uuidValidateV4 } from 'uuid';

import { uploadResource } from '../actions/resourcesActions';

export default {
    name: 'AddShoper',
    components: { NavigationHeader },
    props: {},
    computed: {
        ...mapState('environments', ['environments']),
        ...mapGetters('environments', ['getEnvironment']),

        storedEnvironment() {
            const { environmentId } = this.$router.history.current.params;
            return this.getEnvironment(environmentId);
        },

        nameErrors() {
            const errors = [];
            if (!this.$v.environment.name.$dirty) return errors;
            !this.$v.environment.name.required &&
                errors.push('Title is required.');
            return errors;
        },
    },

    async mounted() {
        const { environmentId } = this.$router.history.current.params;

        const success = await this.fetchEnvironment(environmentId);

        if (!success) {
            this.handleCreateNew();
        }
    },
    data: () => ({
        environment: {
            id: '',
            name: '',
            packageurl: '',
            resources: [],
            backgroundImage: '',
        },
        saved: true,
        isLoading: false,
        isLoadingMessage: '',
    }),
    validations: {
        environment: {
            name: {
                required,
            },
            // environmentid: {
            //     required,
            // },
        },
    },
    methods: {
        ...mapActions('environments', [
            'fetchEnvironment',
            'upsertEnvironment',
            'deleteEnvironment',
        ]),

        handleCreateNew() {
            const { params, query } = this.$router.history.current;

            if (query?.type === 'new' && uuidValidateV4(params.environmentId)) {
                this.environment.id = params.environmentId;
            } else {
                // None valid!
                this.$router.push({ name: 'ViewEnvironments' });
            }
        },

        addImage(file) {
            this.environment.resources = [
                {
                    id: shortid.generate(),
                    url: URL.createObjectURL(file),
                    title: file.name,
                    type: file.type,
                },
            ];
        },

        removeImage() {
            this.environment.resources = [];
        },

        async onSave() {
            this.$v.$touch();

            this.isLoading = true;
            const { id, resources } = this.environment;
            const { query } = this.$router.history.current;

            if (!this.$v.$invalid && id) {
                if (resources[0]?.url.includes('blob:')) {
                    this.isLoadingMessage = `Uploading resources...`;

                    const config = { responseType: 'blob' };
                    const blob = await axios.get(resources[0].url, config);
                    const file = new File([blob.data], resources[0].title);
                    const url = await uploadResource(file);

                    if (url) {
                        this.environment.resources[0] = {
                            ...this.environment.resources[0],
                            url,
                        };
                    }
                }

                await this.upsertEnvironment(this.environment);

                if (query?.type === 'new') {
                    this.$router.replace({ query: null });
                }
            }

            this.isLoading = false;
            this.isLoadingMessage = '';
        },
        async onDelete() {
            const success = await this.deleteEnvironment(this.environment.id);

            if (success) {
                this.$router.push({ name: 'ViewEnvironments' });
            }
        },
    },
    watch: {
        environment: {
            immediate: false,
            handler: function(n) {
                this.saved = isEqual(this.storedEnvironment, n);
            },
            deep: true,
        },
        storedEnvironment: {
            immediate: false,
            handler(n) {
                if (n) {
                    this.environment = cloneDeep(n);
                }
            },
        },
    },
};
</script>

<style>
#editor {
    height: 200px;
}
</style>
