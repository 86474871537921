<template>
    <v-container
        style="position:absolute;pointer-events:none"
        fill-height
        fluid
    >
        <v-row
            no-gutters
            align="end"
            style="height: 100%;padding-bottom:20px"
            v-if="fixture != null && allowNavigation && !isProductFocused"
        >
            <v-spacer />
            <v-btn
                fab
                small
                dark
                class="mr-4"
                style="pointer-events:auto"
                @click="OnChangeBay(false)"
            >
                <v-icon>
                    mdi-arrow-left
                </v-icon>
            </v-btn>
            <v-btn
                dark
                large
                style="pointer-events:auto"
                @click="SetShoppingCartVisibility(true)"
            >
                Shopping Cart
            </v-btn>
            <v-btn
                fab
                small
                dark
                class="ml-4"
                style="pointer-events:auto"
                @click="OnChangeBay(true)"
            >
                <v-icon>
                    mdi-arrow-right
                </v-icon>
            </v-btn>
            <v-spacer />
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapState } from 'vuex';
import { mapMutations } from 'vuex';
export default {
    name: 'FixtureNavigator',
    components: {},
    props: {
        fixture: null,
        isActive: false,
        allowNavigation: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        ...mapGetters('sessionState', ['GetBabPnl', 'GetCurrentStageData']),
        ...mapState('sessionState', ['isProductFocused', 'showShoppingCart']),
    },

    mounted: () => {},
    data: () => ({
        bayIndex: -1,
        initalized: false,
        formerBayIndex: -1,
    }),
    methods: {
        ...mapMutations('sessionState', ['SetShoppingCartVisibility']),

        ...mapMutations('trackedState', ['AddTrackedEvent']),

        OnChangeBay(isNext) {
            let changeAmount = isNext ? 1 : -1;

            if (
                this.bayIndex + changeAmount > -1 &&
                this.bayIndex + changeAmount < this.fixture.waypoints.length
            ) {
                this.bayIndex = this.bayIndex + changeAmount;
            }
        },
    },
    watch: {
        isActive: {
            handler(newVal) {
                console.log('initalizing fixture nav');
                if (newVal && !this.initalized) {
                    var inst = this.GetBabPnl('MainPanel');
                    var scene = inst.scene;
                    scene.actionManager.registerAction(
                        new BABYLON.ExecuteCodeAction(
                            BABYLON.ActionManager.OnKeyUpTrigger,
                            evt => {
                                if (
                                    evt.sourceEvent.key == 'ArrowLeft' ||
                                    evt.sourceEvent.key == 'a'
                                ) {
                                    if (this.bayIndex != 0) {
                                        this.bayIndex -= 1;
                                    }
                                }

                                if (
                                    evt.sourceEvent.key == 'ArrowRight' ||
                                    evt.sourceEvent.key == 'd'
                                ) {
                                    if (
                                        this.bayIndex !=
                                        this.fixture.waypoints.length - 1
                                    ) {
                                        this.bayIndex += 1;
                                    }
                                }
                            },
                        ),
                    );
                }
            },
        },
        fixture: {
            immediate: true,
            handler(newVal) {
                if (newVal != null) {
                    this.bayIndex = 0;
                }
            },
        },
        bayIndex: {
            handler(newVal) {
                if (this.formerBayIndex != -1) {
                    this.AddTrackedEvent({
                        type: 'LEFTBAY',
                        key: this.formerBayIndex,
                    });
                }
                this.AddTrackedEvent({
                    type: 'NAVIGATETOBAY',
                    key: newVal,
                });
                this.$emit('ChangeWaypoint', this.fixture.waypoints[newVal]);
                this.formerBayIndex = newVal;
            },
        },
    },
};
</script>
