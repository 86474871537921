<template>
    <v-layout style="width:100vw;height:100vh">
        <babylon-panel id="MainPanel" @init="OnInit"> </babylon-panel>
        <sphere-navigator
            :babylonPanelIdentifier="'MainPanel'"
            :isActive="navigatorActive"
            :waypoint="targetWaypoint"
            :waypoints="CurrentStageData.waypoints"
            v-on:initalized="OnNavigatorInit"
            v-on:LoadingWaypoint="OnWaypointBeginLoad"
            v-on:WaypointLoaded="OnWaypointLoaded"
            :waypointNavigation="true"
        />
        <fixture-navigator
            :fixture="activeFixture"
            :isActive="fixtureNavActive"
            v-on:ChangeWaypoint="SetTargetWaypoint"
        />
        <!--
    <event-tracker

    />
    -->
        <touchpoint-visualizer
            :touchpoints="CurrentStageData.touchpoints"
            :isActive="touchpointsActive"
            :babylonPanelIdentifier="'MainPanel'"
            v-on:onTouchpointChange="OnTouchpointSelected"
            :waypoint="activeWaypoint"
        />
        <!-- <insight-popup-panel
            :touchpoint="insightTouchpoint"
            v-on:onCloseInsight="insightTouchpoint = null"
        /> -->
        <v-overlay :value="isLoadingProduct">
            <v-layout column="" align-center justify-center>
                <v-progress-circular
                    indeterminate
                    size="64"
                ></v-progress-circular>
                <v-spacer style="height:40px"></v-spacer>
                <v-card class="title ma-4 pa-2" color="transparent" flat>
                    Loading Product...
                </v-card>
            </v-layout>
        </v-overlay>

        <store-viewer
            v-if="activeWaypoint != null"
            :waypointId="activeWaypoint.id"
        />
    </v-layout>
</template>

<script>
import BabylonPanel from '../../Babylon/Panels/BabylonPanel.vue';
import SphereNavigator from '../../Babylon/Navigators/SphereNavigator.vue';
import TouchpointVisualizer from '../../Babylon/Visualizers/TouchpointVisualizer.vue';
// import InsightPopupPanel from '../../Insights/InsightPopupPanel.vue';
import FixtureNavigator from '../../Babylon/Navigators/FixtureNavigator.vue';
import EventTracker from '../../Babylon/Panels/EventTracker.vue';
import StoreViewer from '../../../components/3DStoreViewer/StoreViewer.vue';
import { mapGetters } from 'vuex';
import { mapState } from 'vuex';
import { mapMutations } from 'vuex';

export default {
    name: 'StageEnvironment',
    components: {
        BabylonPanel,
        SphereNavigator,
        TouchpointVisualizer,
        // InsightPopupPanel,
        FixtureNavigator,
        EventTracker,
        StoreViewer,
    },
    props: {},
    computed: {
        ...mapGetters('sessionState', ['GetBabPnl', 'CurrentStageData']),
        ...mapState('sessionState', [
            'showMainLoadScreen',
            'showFadeout',
            'desiredWaypoint',
            'isProductFocused',
        ]),
    },

    mounted: () => {},
    data: () => ({
        navigatorActive: false,
        fixtureNavActive: false,
        touchpointsActive: false,
        activeWaypoint: null,
        insightTouchpoint: null,
        targetWaypoint: null,
        testMatrix: null,
        downPointerPos: null,
        lastFramePos: null,
        pointerDown: false,
        pointerDownTime: null,
        isPaning: false,
        isFocused: false,
        selectedProductData: null,
        focusedProduct: null,
        focusedCollider: null,
        productZoom: 0,
        productSpin: true,
        focusedXRotation: 0,
        focusedYRotation: 0,
        minProductDist: 0,
        maxProductDist: 0,
        zoomAmount: 0,
        isLoadingProduct: false,
        activeFixture: null,
        maxPOVZoom: 0.5,
    }),
    methods: {
        ...mapMutations('sessionState', [
            'SetMainLoadScreenState',
            'SetFadeoutState',
            'SetActiveWaypointid',
            'SetProductFocus',
            'SetFocusedProductData',
        ]),

        ...mapMutations('trackedState', ['AddTrackedEvent']),

        SetTargetWaypoint(waypoint) {
            console.log('Setting target waypoint');
            console.log(waypoint);
            this.targetWaypoint = waypoint;
        },

        OnInit() {
            this.navigatorActive = true;
            this.touchpointsActive = true;
            this.fixtureNavActive = true;
            this.CreateFixtureMatrixes();
            this.CreateClickableHandler();
        },
        OnNavigatorInit() {
            console.log('Navigator initalized, loading first waypoint');
            var startingWaypoint = this.GetStartingWaypoint();

            if (startingWaypoint != null) {
                this.targetWaypoint = startingWaypoint;
            }

            this.AddTrackedEvent({
                type: 'STARTSESSION',
            });
        },
        OnTouchpointSelected(touchpoint) {
            console.log('NewTouchpoint');
            if (touchpoint.type == 'INSIGHT') {
                let closestDistance = 99999;
                let closestWaypoint = null;

                let currentPosition = new BABYLON.Vector3(
                    touchpoint.pos.x,
                    touchpoint.pos.y,
                    touchpoint.pos.z,
                );

                for (
                    let i = 0;
                    i < this.CurrentStageData.waypoints.length;
                    i++
                ) {
                    const waypoint = this.CurrentStageData.waypoints[i];
                    console.log('Checking waypoint');
                    console.log(waypoint);

                    let comparePos = new BABYLON.Vector3(
                        waypoint.pos.x,
                        waypoint.pos.y,
                        waypoint.pos.z,
                    );
                    let distance = BABYLON.Vector3.Distance(
                        comparePos,
                        currentPosition,
                    );

                    console.log('Distance is');
                    console.log(distance);
                    if (distance < closestDistance) {
                        let dirVector = comparePos
                            .subtract(currentPosition)
                            .normalize();
                        let dotProd = BABYLON.Vector3.Dot(
                            dirVector,
                            new BABYLON.Vector3(
                                touchpoint.dir.x,
                                touchpoint.dir.y,
                                touchpoint.dir.z,
                            ),
                        );

                        if (dotProd < 0.2) {
                            continue;
                        }

                        closestDistance = distance;
                        closestWaypoint = waypoint;
                    }
                }

                if (closestWaypoint.id == this.activeWaypoint.id) {
                    this.insightTouchpoint = touchpoint;
                } else {
                    this.targetWaypoint = closestWaypoint;
                }
            }
        },
        OnWaypointLoaded(newWaypoint) {
            this.activeWaypoint = newWaypoint;
            this.targetWaypoint = newWaypoint;
            if (this.showMainLoadScreen) {
                this.SetMainLoadScreenState(false);
            }

            if (this.showFadeout) {
                this.SetFadeoutState(false);
            }
        },
        OnWaypointBeginLoad(newWaypoint) {
            if (!this.showMainLoadScreen) {
                this.SetFadeoutState(true);
            }
        },
        GetStartingWaypoint() {
            console.log('Get starting waypoint');

            if (this.CurrentStageData.waypoints.length == 0) {
                console.log('No waypoints, checking for any planograms');

                var planogramsWithWaypoints = this.CurrentStageData.planograms.filter(
                    e => e.waypoints.length > 0,
                );
                this.activeFixture = planogramsWithWaypoints[0];
                return null;
            } else {
                var matchedWaypoints = this.CurrentStageData.waypoints.filter(
                    e => e.id == this.desiredWaypoint,
                );

                if (matchedWaypoints.length > 0) {
                    return matchedWaypoints[0];
                } else {
                    return this.CurrentStageData.waypoints[0];
                }
            }
        },
        CreateFixtureMatrixes() {
            if (
                this.CurrentStageData.planograms != null &&
                this.CurrentStageData.planograms.length > 0
            ) {
                var inst = this.GetBabPnl('MainPanel');
                var scene = inst.scene;
                var envData = this.CurrentStageData;

                var box2 = BABYLON.Mesh.CreateBox('box2', 1, scene);
                box2.position = new BABYLON.Vector3(
                    envData.planograms[0].colInfo.pos.x,
                    envData.planograms[0].colInfo.pos.y,
                    envData.planograms[0].colInfo.pos.z,
                );
                box2.rotation = new BABYLON.Vector3(
                    BABYLON.Tools.ToRadians(
                        envData.planograms[0].colInfo.euler.x,
                    ),
                    BABYLON.Tools.ToRadians(
                        envData.planograms[0].colInfo.euler.y,
                    ),
                    BABYLON.Tools.ToRadians(
                        envData.planograms[0].colInfo.euler.z,
                    ),
                );
                var matBox2 = new BABYLON.StandardMaterial('matBox2', scene);
                matBox2.diffuseColor = new BABYLON.Color3(0.1, 0.1, 1);
                box2.material = matBox2;
                box2.scaling = new BABYLON.Vector3(
                    envData.planograms[0].colInfo.size.x * 2,
                    envData.planograms[0].colInfo.size.y * 2,
                    envData.planograms[0].colInfo.size.z * 2,
                );
                box2.isVisible = false;

                var matrix = box2
                    .getBoundingInfo()
                    .boundingBox.getWorldMatrix();
                this.testMatrix = BABYLON.Matrix.Compose(
                    new BABYLON.Vector3(1, 1, 1),
                    new BABYLON.Quaternion.FromEulerAngles(
                        box2.rotation.x,
                        box2.rotation.y,
                        box2.rotation.z,
                    ),
                    box2.position,
                );

                var min = new BABYLON.Vector3(
                    envData.planograms[0].lX,
                    envData.planograms[0].lY,
                    envData.planograms[0].lZ,
                );
                var max = new BABYLON.Vector3(
                    envData.planograms[0].mX,
                    envData.planograms[0].mY,
                    envData.planograms[0].mZ,
                );

                var boundingBox = new BABYLON.BoundingBox(
                    min,
                    max,
                    this.testMatrix,
                );
            }
        },
        CreateClickableHandler() {
            var inst = this.GetBabPnl('MainPanel');
            var scene = inst.scene;

            scene.onPointerDown = (eventInfo, pickInfo, pointerEventType) => {
                console.log('Pointer Down');
                this.downPointerPos = new BABYLON.Vector2(
                    scene.pointerX,
                    scene.pointerY,
                );
                this.lastFramePos = new BABYLON.Vector2(
                    scene.pointerX,
                    scene.pointerY,
                );
                this.pointerDown = true;

                var frameTime = new Date();

                if (this.$data.pointerDownTime != null) {
                    var dif =
                        this.pointerDownTime.getTime() - frameTime.getTime();

                    if (Math.abs(dif) < 500) {
                        //this.AddNewUserEvent({type : "PANINGPRODUCT", data :  ""});
                        this.isPaning = true;
                    }
                }

                if (eventInfo.pointerType == 'mouse') {
                    if (eventInfo.button == 2) {
                        //this.AddNewUserEvent({type : "PANINGPRODUCT", data :  ""});
                        this.isPaning = true;
                    }
                }

                this.pointerDownTime = new Date();
            };
            scene.onPointerUp = eventInfo => {
                return;
            };
            scene.onPointerMove = () => {
                if (this.isFocused) {
                    if (this.pointerDown) {
                        if (this.$data.isPaning) {
                            let horizontalInput =
                                (this.lastFramePos.x - scene.pointerX) * 0.001;
                            let vertInput =
                                (this.lastFramePos.y - scene.pointerY) * 0.001;

                            let cameraRight = scene.activeCamera.getDirection(
                                new BABYLON.Vector3(1, 0, 0),
                            );
                            let cameraUp = scene.activeCamera.getDirection(
                                new BABYLON.Vector3(0, 1, 0),
                            );

                            //cameraRight.add(cameraUp);
                            this.$options.focusedObj.translate(
                                cameraRight,
                                -horizontalInput,
                                BABYLON.Space.WORLD,
                            );
                            this.$options.focusedObj.translate(
                                cameraUp,
                                vertInput,
                                BABYLON.Space.WORLD,
                            );

                            cameraRight = cameraRight.scale(-horizontalInput);
                            cameraUp = cameraUp.scale(vertInput);

                            this.$options.panOffset = this.$options.panOffset.add(
                                cameraRight,
                            );
                            this.$options.panOffset = this.$options.panOffset.add(
                                cameraUp,
                            );
                        } else {
                            this.$data.productSpin = false;

                            let rotationAmt =
                                (this.lastFramePos.y - scene.pointerY) * 0.01;

                            this.$options.focusedObj.rotate(
                                BABYLON.Axis.X,
                                rotationAmt,
                                BABYLON.Space.WORLD,
                            );
                            var eulerRot = this.$options.focusedObj.rotationQuaternion.toEulerAngles();

                            this.$data.focusedXRotation +=
                                (this.lastFramePos.y - scene.pointerY) * 0.01;

                            if (Math.abs(this.$data.focusedXRotation) > 1) {
                                this.$options.focusedObj.rotate(
                                    BABYLON.Axis.X,
                                    -(this.lastFramePos.y - scene.pointerY) *
                                        0.01,
                                    BABYLON.Space.WORLD,
                                );
                                this.$data.focusedXRotation -=
                                    (this.lastFramePos.y - scene.pointerY) *
                                    0.01;
                            }

                            this.$options.focusedObj.rotate(
                                BABYLON.Axis.Y,
                                (this.lastFramePos.x - scene.pointerX) * 0.01,
                                BABYLON.Space.LOCAL,
                            );

                            this.$data.focusedYRotation +=
                                (this.lastFramePos.x - scene.pointerX) * 0.01;
                            if (Math.abs(this.$data.focusedYRotation) > 1) {
                                if (
                                    this.$data.focusedProduct.rotationEvent ==
                                    null
                                ) {
                                    this.AddTrackedEvent({
                                        type: 'PRODUCTROTATED',
                                        key: this.focusedProduct.idf,
                                    });

                                    //this.AddNewUserEvent({type : "ROTATEPRODUCT", data :  this.$data.focusedProduct});
                                    this.$data.focusedProduct.rotationEvent = true;
                                }
                            }

                            if (Math.abs(this.$data.focusedYRotation) > 1.7) {
                                if (
                                    this.$data.focusedProduct.backSeenEvent ==
                                    null
                                ) {
                                    this.AddTrackedEvent({
                                        type: 'PRODUCTSEENBACK',
                                        key: this.focusedProduct.idf,
                                    });

                                    //this.AddNewUserEvent({type : "SEENPRODUCTBACK", data :  this.$data.focusedProduct});
                                    this.$data.focusedProduct.backSeenEvent = true;
                                }
                            }

                            //box2.rotate(BABYLON.Axis.X, (lastFramePos.y - scene.pointerY) * 0.01, BABYLON.Space.LOCAL);
                        }
                        this.lastFramePos = new BABYLON.Vector2(
                            scene.pointerX,
                            scene.pointerY,
                        );
                    }
                }
            };

            scene.onPrePointerObservable.add(
                (pointerInfo, eventState) => {
                    var event = pointerInfo.event;
                    var delta = 0;
                    var inst = this.GetBabPnl('MainPanel');
                    var scene = inst.scene;

                    if (event.wheelDelta) {
                        delta = event.wheelDelta;
                    } else if (event.detail) {
                        delta = -event.detail;
                    } else if (event.deltaY) {
                        delta = event.deltaY * -40;
                    }
                    if (delta) {
                        if (this.$data.isFocused) {
                            if (this.$options.focusedObj != null) {
                                this.$data.productZoom += delta / 4000;

                                if (this.$data.productZoom < 0) {
                                    this.$data.productZoom = 0;
                                } else if (this.$data.productZoom > 1) {
                                    this.$data.productZoom = 1;
                                }

                                if (
                                    this.$options.focusedObj.lookedClosed ==
                                    null
                                ) {
                                    if (this.$data.productZoom > 0.5) {
                                        this.AddTrackedEvent({
                                            type: 'PRODUCTDETAILEDLOOK',
                                            key: this.focusedProduct.idf,
                                        });

                                        this.$options.focusedObj.lookedClosed = true;
                                    }
                                }

                                let vec = scene.activeCamera.getFrontPosition(
                                    this.$data.maxProductDist +
                                        (this.$data.minProductDist -
                                            this.$data.maxProductDist) *
                                            this.$data.productZoom,
                                );
                                var cameraUpVector = scene.activeCamera.getDirection(
                                    scene.activeCamera.upVector,
                                );

                                vec = vec.add(this.$options.panOffset);

                                this.$options.focusedObj.position = vec;
                            }
                        } else {
                            scene.activeCamera.fov -= delta / 4000;

                            if (scene.activeCamera.fov > 1) {
                                scene.activeCamera.fov = 1;
                            } else if (
                                scene.activeCamera.fov < this.$data.maxPOVZoom
                            ) {
                                scene.activeCamera.fov = this.$data.maxPOVZoom;
                            }
                        }

                        this.$data.zoomAmount = scene.activeCamera.fov;
                    }
                },
                BABYLON.PointerEventTypes.POINTERWHEEL,
                false,
            );
        },
        SetFocusState(isFocused) {
            var inst = this.GetBabPnl('MainPanel');
            var scene = inst.scene;
            this.SetProductFocus(isFocused);
            if (isFocused) {
                this.AddTrackedEvent({
                    type: 'PRODUCTPICKUP',
                    key: this.focusedProduct.idf,
                });

                this.$data.focusedProduct.rotationEvent = null;

                this.$options.panOffset = new BABYLON.Vector3(0, 0, 0);

                /*
          var left = this.$options.scene.getMeshByName("Left");
          var right = this.$options.scene.getMeshByName("Right");
          left.material.alpha = 0.4;
          right.material.alpha = 0.4;
          */

                //this.ChangeZoomToPercent(0,true);

                scene.activeCamera.fov = 1;
                this.$data.focusedYRotation = 0;
                this.$data.focusedXRotation = 0;

                //this.AddNewUserEvent({type : "PICKUPPRODUCT", data :  this.$data.focusedProduct});

                this.$options.lastFramePos = new BABYLON.Vector2(
                    scene.pointerX,
                    scene.pointerY,
                );
                scene.activeCamera.detachControl(inst.canvas);
            } else {
                /*
            var left = this.$options.scene.getMeshByName("Left");
            var right = this.$options.scene.getMeshByName("Right");
            left.material.alpha = 1;
            right.material.alpha = 1;
            */

                this.$data.focusedProduct = null;

                scene.activeCamera.attachControl(inst.canvas, true);

                if (this.$options.focusedObj != null) {
                    this.$options.focusedObj.dispose();
                    this.$options.focusedObj = null;
                }
            }

            this.isFocused = isFocused;
        },
        GetAllHitsFromCollection(listOfElements, ray) {
            let iterationNum = listOfElements.length;
            let hititem = null;
            let hitArray = new Array();

            for (let i = 0; i < iterationNum; i++) {
                //Foreach item in this array, if it has children lets check inside there
                if (this.IsElementHitByRay(listOfElements[i], ray)) {
                    //If child element is hit, lets go check the child items
                    if (listOfElements[i].parts != null) {
                        let hitChildrenArray = this.CheckElementLevel(
                            listOfElements[i].parts,
                            ray,
                        );

                        for (let t = 0; t < hitChildrenArray.length; t++) {
                            if (this.IsElementClickable(hitChildrenArray[t])) {
                                hitArray.push(hitChildrenArray[t]);
                            }
                        }
                    }

                    if (this.IsElementClickable(listOfElements[i])) {
                        hitArray.push(listOfElements[i]);
                    }
                }
            }

            if (hitArray.length > 0) {
                let closestItem;
                let closestDistance = 99999999;

                for (let d = 0; d < hitArray.length; d++) {
                    var distanceVec = Math.abs(
                        BABYLON.Vector3.Distance(
                            ray.origin,
                            new BABYLON.Vector3(
                                hitArray[d].colInfo.pos.x,
                                hitArray[d].colInfo.pos.y,
                                hitArray[d].colInfo.pos.z,
                            ),
                        ),
                    );

                    if (distanceVec < closestDistance) {
                        closestDistance = distanceVec;
                        closestItem = hitArray[d];
                    }
                }

                hititem = closestItem;
            }

            return hititem;
        },
        IsElementHitByRay(element, parentRay) {
            if (element.bounds == null) {
                if (
                    element.colInfo.tag == 'FACING' ||
                    element.colInfo.tag == 'Facing'
                ) {
                    //Debug box spawning
                    if (false) {
                        var inst = this.GetBabPnl('MainPanel');
                        var scene = inst.scene;
                        let box = BABYLON.Mesh.CreateBox('box', 1, scene);

                        box.position = new BABYLON.Vector3(
                            element.colInfo.pos.x,
                            element.colInfo.pos.y,
                            element.colInfo.pos.z,
                        );
                        box.rotation = new BABYLON.Vector3(
                            BABYLON.Tools.ToRadians(element.colInfo.euler.x),
                            BABYLON.Tools.ToRadians(element.colInfo.euler.y),
                            BABYLON.Tools.ToRadians(element.colInfo.euler.z),
                        );
                        box.scaling = new BABYLON.Vector3(
                            element.colInfo.size.x * 2,
                            element.colInfo.size.y * 2,
                            element.colInfo.size.z * 2,
                        );

                        let boxmat = new BABYLON.StandardMaterial(
                            'testmat-' + element.refid,
                            scene,
                        );
                        boxmat.diffuseColor = new BABYLON.Color3(
                            Math.random(),
                            Math.random(),
                            Math.random(),
                        );
                        box.material = boxmat;
                    }
                    //-----------------
                }

                let matrix = BABYLON.Matrix.Compose(
                    new BABYLON.Vector3(1, 1, 1),
                    new BABYLON.Quaternion.FromEulerAngles(
                        BABYLON.Tools.ToRadians(element.colInfo.euler.x),
                        BABYLON.Tools.ToRadians(element.colInfo.euler.y),
                        BABYLON.Tools.ToRadians(element.colInfo.euler.z),
                    ),
                    new BABYLON.Vector3(
                        element.colInfo.pos.x,
                        element.colInfo.pos.y,
                        element.colInfo.pos.z,
                    ),
                );

                let min = new BABYLON.Vector3(
                    -element.colInfo.size.x,
                    -element.colInfo.size.y,
                    -element.colInfo.size.z,
                );
                let max = new BABYLON.Vector3(
                    element.colInfo.size.x,
                    element.colInfo.size.y,
                    element.colInfo.size.z,
                );

                element.bounds = new BABYLON.BoundingBox(
                    min,
                    max,
                    this.testMatrix,
                );
                element.matrix = matrix;
            }

            var ray = new BABYLON.Ray(
                new BABYLON.Vector3(0, 0, 0),
                new BABYLON.Vector3(0, 0, 0),
                100,
            );

            BABYLON.Ray.TransformToRef(
                parentRay,
                BABYLON.Matrix.Invert(element.matrix),
                ray,
            );
            return ray.intersectsBox(element.bounds);
        },
        CheckElementLevel(listOfElements, ray) {
            let iterationNum = listOfElements.length;
            let hitArray = new Array();

            for (let i = 0; i < iterationNum; i++) {
                if (this.IsElementHitByRay(listOfElements[i], ray)) {
                    //If child element is hit, lets go check the child items
                    if (listOfElements[i].parts != null) {
                        let hitChildrenArray = this.CheckElementLevel(
                            listOfElements[i].parts,
                            ray,
                        );

                        for (let t = 0; t < hitChildrenArray.length; t++) {
                            hitArray.push(hitChildrenArray[t]);
                        }
                    }

                    hitArray.push(listOfElements[i]);
                }
            }

            return hitArray;
        },
        IsElementClickable(element) {
            if (element.colInfo.tag == 'Block') {
                if (element.parts == null) {
                    return true;
                } else {
                    return false;
                }
            } else if (element.colInfo.tag == 'Facing') {
                return true;
            } else if (element.colInfo.tag == 'FACING') {
                return true;
            }

            return false;
        },
        OnLinearNavigation(isNext) {},
    },
    watch: {
        CurrentStageData: {
            handler(newVal) {
                console.log('StageDataChange');
                this.targetWaypoint = this.GetStartingWaypoint();
            },
        },
        desiredWaypoint: {
            handler(newVal) {
                this.targetWaypoint = this.GetStartingWaypoint();
            },
        },
        activeWaypoint: {
            handler(newVal) {
                this.SetActiveWaypointid(newVal.id);
            },
        },
        isProductFocused: {
            handler(newVal) {
                if (this.isFocused != newVal) {
                    this.SetFocusState(newVal);
                }
            },
        },
        focusedProduct: {
            handler(newVal) {
                this.SetFocusedProductData(this.focusedProduct);
            },
        },
    },
    beforeDestroy() {
        this.SetMainLoadScreenState(true);
    },
    beforeRouteUpdate(to, from, next) {
        this.SetMainLoadScreenState(true);
        next();
    },
};
</script>
